<template>
  <section class="container cta">
    <div class="flex flex-wrap md:justify-between md:items-center">
      <div class="w-full h-full flex md:justify-center md:items-center lg:w-4/12 md:mx-auto mb-9 lg:mb-0 md:relative md:h-[550px]">
        <img
          loading="lazy"
          v-if="mainImage"
          :data-src="mainImage.image_url"
          data-transformation="e_trim"
          :alt="mainImage.title || ''"
        >
      </div>
      <div class="w-full flex flex-col px-4 lg:w-5/12">
        <h2 class="text-h3 text-black leading-small lg:text-h1 font-bold break-normal" data-testid="header-title">
          {{ familyName }}
          <span v-if="name" class="text-h4 lg:text-h2 font-normal block mt-2.5 md:mt-4" data-testid="header-subtitle">
            {{ name }}
          </span>
        </h2>
        <p v-if="ctaDescription" class="mt-4 text-base text-dark-grey">
          {{ ctaDescription }}
        </p>
        <div v-if="primaryButton || secondaryButton" class="mt-10 flex flex-col sm:flex-row">
          <Button
            v-if="primaryButton && !isPreRelease"
            :text="primaryButton.label"
            :title="primaryButton.label"
            :type="primaryButton.type"
            :url="primaryButton.link"
            :target="primaryButton.open_new_tab ? '_blank' : '_self'"
            :icon="true"
            data-testid="primary-btn" />
          <Button
            v-else-if="primaryButton && isHubSpotEnabled && isPreRelease"
            :text="keepMePostedLabel"
            :title="keepMePostedLabel"
            :type="primaryButton.type"
            @click="toggleHubSpotForm"
            :icon="true" />
          <span v-if="isLocallyEnabled && !isPreRelease" id="lcly-button-10" class="lcly-wrapper !text-black mt-4 sm:mt-0 sm:ml-4">
            <Button
              v-if="secondaryButton"
              id="lcly-link-10"
              :url="fallbackUrl"
              :text="secondaryButton.label"
              :title="secondaryButton.label"
              :icon="true"
              :type="secondaryButton.type"
              data-testid="locally-btn" />
            <svg v-if="secondaryButton" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" class="lcly-primary-trigger__icon">
              <path d="M12.95 10.707l0.707-0.707-5.657-5.657-1.414 1.414 4.242 4.243-4.242 4.243 1.414 1.414 4.95-4.95z"></path>
            </svg>
          </span>
          <Button
            v-else-if="secondaryButton && !isPreRelease"
            :text="secondaryButton.label"
            :title="secondaryButton.label"
            :type="secondaryButton.type"
            :url="secondaryButton.link"
            :target="secondaryButton.open_new_tab ? '_blank' : '_self'"
            class="text-left mt-4 sm:mt-0 sm:ml-4 !text-black"
            :icon="true"
            data-testid="secondary-btn" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { Button } from '@ponbike/vue-components'
import { createLocallyScript } from '../../../helpers/locally.js'
import { addActiveSwatchesTags } from '../../../helpers/image.js'

export default {
  name: 'ProductCTA',
  components: {
    Button
  },
  mounted() {
    if (this.isLocallyEnabled) {
      createLocallyScript({
        id: 10,
        button_text: this.secondaryButton?.label
      })
    }
  },
  computed: {
    ...mapGetters('product', [
      'uniqueFilteredColorIdentifiers',
      'getImageByTags',
      'getButtonByTag',
      'getConfigByTag',
      'getContent',
      'name',
      'familyName',
      'locale',
      'isLocallyEnabled',
      'isPreRelease',
      'isHubSpotEnabled'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    preferredAngle() {
      const angle = 'angle-front-side'
      const coloredImages = this.uniqueFilteredColorIdentifiers.map(color => this.getImageByTags(color, angle)).filter(color => Object.keys(color).length !== 0)
      if (this.uniqueFilteredColorIdentifiers.length === coloredImages.length) {
        return angle
      }

      return 'angle-side-view'
    },
    mainImage() {
      const tags = addActiveSwatchesTags([this.preferredAngle])

      return this.getImageByTags(...tags)
    },
    primaryButton() {
      return this.getButtonByTag('request_configure_bike_one')
    },
    secondaryButton() {
      return this.getButtonByTag('request_configure_bike_two')
    },
    ctaDescription() {
      return this.getContent('request_configure_bike_description')
    },
    fallbackUrl() {
      return this.getConfigByTag('locally_fallback_store_locator')?.value
    },
    keepMePostedLabel() {
      return this.getContent('keep_me_posted')
    },
  },
  methods: {
    toggleHubSpotForm(){
      const showStockWarningModalEvent = new CustomEvent('showStockWarningModal:show')
      document.dispatchEvent(showStockWarningModalEvent)
    }
  }
}
</script>

<style>
.cta img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.cta .lcly-wrapper {
  position: relative;
}

.cta .lcly-primary-trigger {
  width: 100%;
  border: none;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;
  padding: 12px 64px 12px 20px;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: #f2f2f2;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.cta .lcly-primary-trigger:hover {
  background-color: #e8e8e8;
}

.cta .lcly-primary-trigger__icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 20px;
  pointer-events: none;
}

.cta #lcly-button-0 a {
  background-color: #f2f2f2;
}
</style>
