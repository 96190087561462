const initialState = () => ({
  data: {}
})

const getters = {
  data: state => state.data,
  review: state => state.data?.review,
  price: state => state.data?.price,
  name: state => state.data?.product?.name,
  brand: state => state.data?.product?.brand,
  productId: state => state.data?.product?.id,
  sku: state => state.data?.product?.sku,
  accessories: state => state.data?.accessories,
  relatedProducts: state => state.data?.related_products,
  isConnected: state => state.data?.product?.connected,
  isEbike: state => state.data?.motor,
  isEclipseBike: state => state.data?.product?.family.includes('Eclipse'),
  isBikeWithArModel: state => state.data?.ar_models?.length > 0,
  badge: state => state.data?.product.badge,
  description: state => state.data?.product?.description,
  shortDescription: state => state.data?.product?.short_description,
  familyName: state => state.data?.product?.family,
  isPreRelease: state => state.data?.product?.pre_release,
  isPrivateLease: state => state.data?.product?.private_lease,
  bikesInFamily: state => state.data?.product?.bikes_in_family,
  locale: state => state.data?.locale,
  breadcrumbs: state => state.data?.breadcrumbs,
  availableColors: state => state.data?.colors,
  availableFilteredColors: state => state.data?.colors?.filter((color) => !color.tag_identifier.includes('-bg') && !color.tag_identifier.includes('-text')),
  uniqueColorIdentifiers: state => state.data?.colors?.map(color => color.tag_identifier) ?? [],
  availableFrames: state => {
    const frames = []
    state.data?.assets?.forEach(asset => {
      if (asset.tags.includes('frame-low') && !frames.includes('frame-low')) {
        frames.push('frame-low')
      }
      if (asset.tags.includes('frame-mid') && !frames.includes('frame-mid')) {
        frames.push('frame-mid')
      }
      if (asset.tags.includes('frame-high') && !frames.includes('frame-high')) {
        frames.push('frame-high')
      }
    })

    return frames
  },
  uniqueFilteredColorIdentifiers: state => state.data?.colors?.flatMap(color => !color.tag_identifier.includes('-bg') && !color.tag_identifier.includes('-text') ? [color.tag_identifier] : []),
  getContent: (state) => (tag) => state.data?.content?.find((content) => content.tag === tag)?.value ?? tag,
  // eslint-disable-next-line
  getImageByTags: (state) => (...tags) => state.data?.assets?.find(img => tags.every(tag => img.tags.includes(tag))) ?? {},
  // eslint-disable-next-line
  getColorByTag: (state) => (tag) => state.data?.colors?.find((color) => color.tag_identifier === tag) ?? null,
  // eslint-disable-next-line
  getFrameByTag: (state) => (tag) => state.data?.frames?.find((frame) => frame.tag_identifier === tag) ?? null,
  // eslint-disable-next-line
  filterImageByTags: (state) => (...tags) => state.data?.assets?.filter(img => tags.every(tag => img.tags.includes(tag))) ?? [],
  getButtonByTag: (state) => (tag) => state.data?.buttons?.find((button) => button.tag === tag) ?? null,
  getConfigByTag: (state) => (tag) => state.data?.config?.find((config) => config.tag === tag) ?? null,
  getUrlByTag: (state) => (tag) => state.data?.urls?.find((url) => url.tag === tag)?.value ?? null,
  // eslint-disable-next-line
  getARModelByTags: (state) => (...tags) => state.data?.ar_models?.find(model => tags.every(tag => model.tags.includes(tag))) ?? {},
  // eslint-disable-next-line
  isLocallyEnabled: (_state, getters) => Boolean(getters.getConfigByTag('locally_company_id')),
  // eslint-disable-next-line
  isHubSpotEnabled: (_state, getters) => Boolean(getters.getConfigByTag('hubSpotEnabled')),
  // eslint-disable-next-line
  hasAwards: (_state, getters) => Boolean(getters.filterImageByTags('award')),
  getFeatures: state => state.data?.features,
  specifications: state => state.data?.specifications,
  motor: state => state.data?.motor,
  battery: state => state.data?.battery_info,
  // eslint-disable-next-line
  batterySpecifications: (_state, getters) => getters.battery?.battery_specifications,
  usps: state => state.data?.usp,
  video: state => state.data?.video,
  content: state => state.data?.content,
  quote: state => state.data?.quote,
  ar_models: state => state.data?.ar_models,
  scrollable: state => state.data?.scrollable
}

const actions = {}

const mutations = {
  setData (state, data) {
    // eslint-disable-next-line
    state.data = data
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
