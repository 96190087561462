<template>
  <section
    v-if="transformedVideoUrl && posterImageUrl"
    id="family-video-wrapper"
    class="relative sm:h-[66vh] md:h-[80vh] bg-light-grey w-[85%] mx-auto"
  >
    <video
      id="familyVideo"
      ref="familyVideo"
      playsinline
      muted
      loop
      :poster="posterImageUrl"
      preload="none"
      class="w-full sm:h-full object-cover aspect-[4/5] sm:aspect-auto"
    >
      <source
        type="video/mp4"
        :src="transformedVideoUrl"
      >
    </video>
    <button
      data-testid="mute-button"
      class="absolute left-auto right-[25px] md:right-auto top-[50px] md:left-[50px] w-[58px] h-[58px] bg-blue rounded-full flex items-center justify-center md:w-[84px] md:h-[84px]"
      @click="toggleMute()"
    >
      <svg
        v-if="isMuted"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#ffffff"
        viewBox="0 0 256 256"
      >
        <path d="M52.44,36A6,6,0,0,0,43.56,44L78,81.94l-.08.06H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.94l70.38,54.74A6,6,0,0,0,158,224V169.92L203.56,220a6,6,0,0,0,8.88-8.08ZM30,160V96a2,2,0,0,1,2-2H74v68H32A2,2,0,0,1,30,160Zm116,51.73L86,165.07V90.93l.11-.08L146,156.72Zm41.5-66.53a26,26,0,0,0,0-34.37,6,6,0,1,1,9-7.93,38,38,0,0,1,0,50.24,6,6,0,0,1-9-7.94ZM107.41,66.68a6,6,0,0,1,1.06-8.42l39.85-31A6,6,0,0,1,158,32v74.83a6,6,0,0,1-12,0V44.27L115.83,67.73A6,6,0,0,1,107.41,66.68ZM246,128a77.86,77.86,0,0,1-19.86,52,6,6,0,1,1-8.94-8,66,66,0,0,0,0-88,6,6,0,1,1,8.94-8A77.86,77.86,0,0,1,246,128Z"></path>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="#ffffff"
        viewBox="0 0 256 256"
      >
        <path d="M154.64,26.61a6,6,0,0,0-6.32.65L77.94,82H32A14,14,0,0,0,18,96v64a14,14,0,0,0,14,14H77.94l70.38,54.74A6,6,0,0,0,158,224V32A6,6,0,0,0,154.64,26.61ZM30,160V96a2,2,0,0,1,2-2H74v68H32A2,2,0,0,1,30,160Zm116,51.73L86,165.07V90.93l60-46.66Zm50.53-108.85a38,38,0,0,1,0,50.24,6,6,0,1,1-9-7.94,26,26,0,0,0,0-34.37,6,6,0,0,1,9-7.93ZM246,128a77.86,77.86,0,0,1-19.86,52,6,6,0,1,1-8.94-8,66,66,0,0,0,0-88,6,6,0,1,1,8.94-8A77.86,77.86,0,0,1,246,128Z"></path>
      </svg>
    </button>
  </section>
</template>

<script>
import gsap from 'gsap/dist/gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'
import { isSSR } from '../../../helpers/application.js'

export default {
  data() {
    return {
      isMuted: true
    }
  },
  mounted() {
    if (this.transformedVideoUrl && this.posterImageUrl) {
      gsap.registerPlugin(ScrollTrigger)
      this.initTimeline()
    }
  },
  computed: {
    ...mapGetters('product', [
      'getImageByTags'
    ]),
    aspectRatio() {
      if (isSSR) {
        return
      }

      let aspectRatio = 'ar_4:5'

      if (window.matchMedia('(min-width: 750px)').matches) {
        aspectRatio = 'ar_23:9'
      }

      return aspectRatio
    },
    videoUrl() {
      if (isSSR) {
        return
      }

      let tag = 'family-video-mobile'

      if (window.matchMedia('(min-width: 750px)').matches) {
        tag = 'family-video'
      }

      const video = this.getImageByTags(tag)
      return video?.image_url
    },
    transformedVideoUrl() {
      let transformations = [
        'q_auto',
        'f_auto',
        'dpr_auto',
        'w_iw',
        this.aspectRatio,
        'c_fill'
      ]

      return transformUrl(this.videoUrl, transformations, true)
    },
    posterImageUrl() {
      let transformations = [
        'so_0',
        'f_jpg',
        'w_iw',
        this.aspectRatio,
        'c_fill'
      ]

      return transformUrl(this.videoUrl, transformations, true)
    }
  },
  methods: {
    getVideoIsPlaying() {
      const video = this.$refs.familyVideo

      return video.currentTime > 0 && !video.paused
    },
    initTimeline() {
      gsap.timeline({
        scrollTrigger: {
          trigger: '#gazelle-pdp-header',
          scrub: true,
          start: 'top -15%',
          end: 'bottom -50%',
          markers: false,
          onEnter: () => !this.getVideoIsPlaying() && this.$refs.familyVideo.play(),
          onEnterBack: () => !this.getVideoIsPlaying() && this.$refs.familyVideo.play(),
          onLeave: () => this.getVideoIsPlaying() && this.$refs.familyVideo.pause(),
          onLeaveBack: () => this.getVideoIsPlaying() && this.$refs.familyVideo.pause()
        }
      }).to('#family-video-wrapper', { width: '100%' })
    },
    toggleMute() {
      const video = this.$refs.familyVideo

      video.muted = !video.muted
      this.isMuted = !this.isMuted
    }
  }
}
</script>
