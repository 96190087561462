<template>
  <section class="pb-24 md:py-24">
    <ProductOverview
      v-if="relatedProducts && relatedProducts.length"
      :products="relatedProducts"
      :sectionTitle="sectionTitle"
      :allowCompare="true"/>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductOverview from '../ProductOverview/ProductOverview.vue'

export default {
  name: 'RelatedProducts',
  components: {
    ProductOverview
  },
  computed: {
    ...mapGetters('product', [
      'relatedProducts',
      'getContent'
    ]),
    sectionTitle() {
      return this.getContent('related_products_label')
    }
  }
}
</script>
