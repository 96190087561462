<template>
  <section
    v-if="activeModelUrl"
  >
    <SideView />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import SideView from './SideView.vue'

export default {
  components: {
    SideView
  },
  computed: {
    ...mapGetters('product', [
      'getARModelByTags'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    activeModelUrl() {
      const model = this.getARModelByTags(this.activeColor, this.activeFrame)
      return model?.file_url
    }
  }
}
</script>

<style scoped>
section {
  background-image: linear-gradient(to bottom, theme('colors.light-black'), #cbcbcb);
}
</style>
