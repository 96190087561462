import productJson from './api/c8-hmb-belt-connect.json'

const app = 'pdp'
const productData = productJson.body

export default {
  config: {
    app,
    productData
  }
}
