<template>
  <div v-show="getFeatures"
       :class="{ 'lg:mt-28' : !awardsAvailable }">
    <section class="bg-[#000] hidden lg:!block">
      <div v-for="(content, index) in getFeatures"
           :key="content.id">
        <div v-if="content.tags.includes('feature') && !content.tags.includes('video')">
          <div v-show="getImage(content.id)"
          :style="[{backgroundImage:`url(${getBackgroundImage(content.id)})`}, getImagePosition(content.id), [index % 2 === 0 ? selectedColor : selectedColorSecondary]]"
          class="lg:min-h-[80vh] feature">
            <div class="container">
              <div class="flex flex-wrap items-center lg:min-h-[80vh]"
               :class="[getImagePosition(content.id) === 'background-position: left' ? 'flex-row-reverse' : '']">
                <div class="w-full flex flex-col p-[35px] lg:w-4/12 md:flex bg-[#fff] lg:bg-transparent">
                  <h3 :key="textColorOverwrite" :style="{color: `rgb(${textColorOverwrite})`}" class="text-black lg:text-white text-h2 leading-normal font-bold">{{ content.title }}</h3>
                  <p :key="textColorOverwrite" :style="{color: `rgb(${textColorOverwrite})`}" class="text-white mt-3 text-base font-normal">{{ content.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div v-for="content in getFeatures"
             :key="content.id"
             class="lg:hidden">
        <div v-if="content.tags.includes('feature') && !content.tags.includes('video')">
          <div class="container"
             v-show="getImage(content.id)">
            <div class="w-full px-4 my-12">
              <h3 class="text-black font-bold text-[32px] leading-normal">{{ content.title }}</h3>
              <p class="text-black mt-3 text-base font-normal" >{{ content.description }}</p>
            </div>
          </div>
          <div class="bg-[#000]"
             v-show="getImage(content.id)">
            <div :style="selectedColor">
              <img loading="lazy"
                   :data-src="getImage(content.id)"
                   :alt="getImageAlt(content.id)"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'

export default {
  name: 'productFeatures',
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getColorByTag',
      'getImageByTags',
      'getFeatures',
      'availableFilteredColors',
      'availableColors',
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    awardsAvailable() {
      return this.filterImageByTags('award').length >= 1
    },
    selectedColor() {
      return {
        backgroundColor: 'rgba(' + this.bgColorOverwrite + ',0.8)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }
    },
    selectedColorSecondary() {
      return {
        backgroundColor: 'rgba(' + this.bgColorOverwrite + ',0.75)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }
    },
    textColorOverwrite() {
      let textColor = {}
      const foundColorOverwrite = this.availableColors?.find((color) => color.tag_identifier.includes(`${this.activeColor}-text`) ?? undefined)

      if(foundColorOverwrite != undefined && foundColorOverwrite.tag_identifier) {
         textColor = foundColorOverwrite.tag_identifier.includes('-text') ? foundColorOverwrite.primary_rgb : textColor
      }

      return textColor
    },
    bgColorOverwrite() {
      if (!this.getColorByTag(this.activeColor)) {
        return ''
      }

      let bgColor = this.getColorByTag(this.activeColor).primary_rgb
      const foundColorOverwrite = this.availableColors?.find((color) => color.tag_identifier.includes(`${this.activeColor}-bg`) ?? undefined)

      if(foundColorOverwrite != undefined && foundColorOverwrite.tag_identifier) {
        bgColor = foundColorOverwrite.tag_identifier.includes('-bg') ? foundColorOverwrite.primary_rgb : bgColor
      }

      return bgColor
    }
  },
  methods: {
    getImage(input) {
      let image = this.getImageByTags(`feature-${input}`, this.activeColor)
      if (!image || Object.keys(image).length === 0) {
        return ''
      }

      return image.image_url
    },
    getImageAlt(input) {
      let image = this.getImageByTags(`feature-${input}`, this.activeColor)
      if (!image || Object.keys(image).length === 0) {
        return ''
      }
      return image.title || ''
    },
    getBackgroundImage(input) {
      return transformUrl(this.getImage(input))
    },
    getImagePosition(input) {
      const image = this.getImageByTags(`feature-${input}`, this.activeColor)

      if (image.tags?.includes('feature-left')) {
        return 'background-position: left'
      }
      return 'background-position: right'
    }
  }
}
</script>

<style scoped>
.feature .container::before,
.feature .container::after {
  display: none;
}
</style>
