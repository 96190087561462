<template>
  <div v-if="displayModel && displayDescription" class="bg-dark-grey">
    <section class="hidden lg:!block xl:pt-[150px] relative">
      <figure v-if="displayInfo?.image_url" class="absolute bottom-0 left-0 h-full w-1/2 m-0">
        <img
          loading="lazy"
          :data-src="displayInfo.image_url"
          :alt="displayInfo.title"
          data-transformation="e_trim"
          class="w-full h-full object-contain object-bottom" />
      </figure>
      <div class="relative">
        <div class="container content-container">
          <div class="flex flex-wrap items-center justify-end lg:min-h-[80vh]">
            <div class="w-full flex flex-col p-[32px] lg:w-5/12 md:flex bg-[#fff] lg:bg-transparent">
              <h3 class="text-black text-h2 leading-normal lg:text-white font-bold">{{ displayModel }}</h3>
              <p class="text-black lg:text-white text-base font-normal">{{ displayDescription }}</p>
            </div>
          </div>
        </div>
        <div class="absolute right-0 bottom-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
      </div>
    </section>
    <section class="lg:hidden">
      <div>
        <div class="container content-container">
          <div class="text-center lg:text-left flex flex-col items-center justify-center px-4 py-12 lg:items-start grid col-span-1 lg:col-span-5 xl:col-span-4">
            <h2 class="text-white font-bold text-[32px] lg:text-h2 lg:pt-[240px] leading-none md:leading-large mb-5">
              {{ displayModel }}
            </h2>
            <p class="text-white text-base font-normal max-w-[471px] lg:max-w-full">
              {{ displayDescription }}
            </p>
          </div>
        </div>
        <div class="relative">
          <img
            loading="lazy"
            :data-src="displayInfo.image_url"
            data-transformation="e_trim"
            :alt="displayInfo.title"
            />
          <div class="absolute right-0 bottom-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getImageByTags',
      'getContent',
      'availableFilteredColors'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    displayInfo() {
      let display = this.getImageByTags('display', 'usp', this.activeColor.tag_identifier)
      if(!Object.keys(display).length) {
        display = this.getImageByTags('display', 'usp')

        this.availableFilteredColors.every((color) => {
          const tempGalleryImages = this.filterImageByTags(
            'display',
            'usp',
            color.tag_identifier
          )

          if (tempGalleryImages.length >= 1) {
            display = tempGalleryImages[0];
            return false
          }

          return true
        })
      }
      return display
    },
    displayModel() {
      return this.getContent('display_model')
    },
    displayDescription() {
      return this.getContent('display_description')
    }
  }
}
</script>
