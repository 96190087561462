import createApp from './app.js'
import store from './store/index.js'

(async () => {
  const { app } = await createApp()
  if (window.INITIAL_STATE) {
    store.replaceState(window.INITIAL_STATE)
  }

  delete window.INITIAL_STATE
  app.mount('#app', true)
})()
