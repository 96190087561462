<template>
  <div ref="structuredData"/>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'

const BASE_PRODUCT_STRUCTURED_DATA = {
  '@context': 'https://schema.org/',
  '@type': 'Product'
}

export default {
  name: 'structuredData',
  mounted () {
    const container = this.$refs.structuredData
    if (!container) {
      return
    }
    if (document.getElementById('seoStructuredData')) {
      document.getElementById("seoStructuredData").remove()
    }

    const script = document.createElement('script')
    script.setAttribute('id', 'seoStructuredData')
    script.setAttribute('type', 'application/ld+json')
    script.innerHTML = this.structuredDataJson
    container.innerHTML = ''
    container.appendChild(script)
  },
  computed: {
    ...mapGetters('product', [
      'familyName',
      'name',
      'brand',
      'sku',
      'description',
      'filterImageByTags',
      'getUrlByTag',
      'review',
      'price'
    ]),
    structuredDataJson () {
      return JSON.stringify(this.structuredData, null, 2)
    },
    structuredData () {
      return Object.fromEntries(Object.entries({
        ...BASE_PRODUCT_STRUCTURED_DATA,
        ...{
          'model': this.familyName,
          'name': this.familyName ? this.familyName + ' ' + this.name : this.name,
          'sku': this.sku,
          'description': this.description,
          'image': this.images.length > 0 ? this.images[0] : null,
          'category': this.getUrlByTag('family'),
          'brand': this.structuredDataBrand,
          'aggregateRating': this.structuredDataReview,
          'offers': this.structuredDataOffer,
          'images': this.images
        }
      // eslint-disable-next-line
      }).filter(([_key, value]) => Boolean(value)))
    },
    images () {
      return this.filterImageByTags('angle-side-view').map((image) => transformUrl(image.image_url))
    },
    structuredDataBrand () {
      return {
        '@type': 'Brand',
        'name': this.brand
      }
    },
    structuredDataReview () {
      return this.review && this.review.summary.count ? {
        '@type': 'AggregateRating',
        'ratingValue': this.review.summary.rating,
        'reviewCount': this.review.summary.count
      } : null
    },
    structuredDataOffer () {
      return {
        '@type': 'Offer',
        'priceCurrency': this.price.currency,
        'price': this.price.final_price,
        'itemCondition': 'https://schema.org/NewCondition',
        'url': this.getUrlByTag('product-page')
      }
    }
  }
}
</script>
