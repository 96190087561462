<template>
  <div class="gazelle-preloader-wrapper fixed inset-0 bg-white/90 z-[9999] flex items-center justify-center h-screen">
    <div class="container">
      <div class="loading-mask">
        <div class="loader gazelle-loader"></div>
      </div>
      <div class="gazelle-preloader-bar-wrapper bg-light-grey">
        <div
          class="gazelle-preloader-bar h-1 mt-12 bg-blue w-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isSSR } from '../../../helpers/application.js'

export default {
  mounted() {
    if (isSSR) {
      return
    }

    document.body.style.overflow = 'hidden'
  }
}
</script>

<style scoped>
.gazelle-preloader-wrapper {
  backdrop-filter: blur(4px);
}

.loader {
  transform: translateY(-150%) translateX(-50%);
}

.gazelle-preloader-bar {
  background: linear-gradient(to left, theme('colors.blue'), theme('colors.white'));
}
</style>
