<template>
  <Breadcrumbs :items="linkedBreadcrumbs" separator="\e913" class="text-dark-grey text-tiny" />
</template>

<script>
import { mapGetters } from 'vuex'
import { Breadcrumbs } from '@ponbike/vue-components'

export default {
  name: 'ProductBreadcrumbs',
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapGetters('product', [
      'breadcrumbs',
      'getUrlByTag'
    ]),
    linkedBreadcrumbs() {
      let items = []

      this.breadcrumbs.forEach(item => {
        if (item.identifier === 'breadcrumb_configure') {
          return
        }

        items.push({
          label: item.label,
          link: this.getUrlByTag(item.identifier)
        })
      })

      return items
    }
  }
}
</script>

<style>
.pon-breadcrumbs li a {
  @apply transition-all duration-200;
}

.pon-breadcrumbs li a:hover {
  @apply text-black;
}

.pon-breadcrumbs li + li::before {
  @apply font-vue-icons text-[8px] mt-[1px];
}
</style>
