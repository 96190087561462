<template>
  <section v-if="quote" class="bg-grey py-[50px] sm:py-[75px]">
    <div class="container xl:max-w-screen-lg">
      <div class="flex flex-col justify-between sm:flex-row">
        <div class="w-full sm:w-2/6 flex sm:block items-end">
            <img loading="lazy" :data-src="quoteAuthorImage" :alt="quoteAuthor" class="w-1/2 sm:w-full" data-testid="quote-author-image" />
            <div class="mt-5 ml-3 sm:ml-0 ">
              <h4 class="sm:text-h4 font-bold text-black leading-normal mt-5" data-testid="quote-author">{{ quoteAuthor }}</h4>
              <span class="text-tiny sm:text-base text-light-black" data-testid="quote-author-title">{{ quoteAuthorTitle }}</span>
            </div>
        </div>
        <div class="self-center mt-5 sm:mt-0 w-full sm:w-3/6">
          <h3 class="text-[24px] mt-3 sm:mt-0 sm:text-h3 font-bold leading-normal" data-testid="quote-title">{{ quoteTitle }}</h3>
          <p class="mt-3 text-dark-grey" data-testid="quote-text">{{ quoteText }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('product', [
      'quote',
      'getImageByTags'
    ]),
    quoteTitle() {
      return this.quote?.title
    },
    quoteText() {
      return this.quote?.quote_text
    },
    quoteAuthor() {
      return this.quote?.author
    },
    quoteAuthorTitle() {
      return this.quote?.author_title
    },
    quoteAuthorImage() {
      return this.quote?.author_image
    }
  }
}
</script>
