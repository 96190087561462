// eslint-disable-next-line
import { gsap, Power4 } from 'gsap/dist/gsap'
// eslint-disable-next-line
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

// eslint-disable-next-line
export const createScrollableScene = (
  canvasId,
  frames,
  frameLocation,
  trigger,
  sceneEnd = '+=3750',
  stretch = true,
  clearCanvas = false,
  offset = 0
  // eslint-disable-next-line
) => {
  gsap.registerPlugin(ScrollTrigger)

  // Setup preloader
  const preloadFrames = (frames / 4)
  const preloaderSelector = document.querySelector('.gazelle-preloader-wrapper')
  const preloaderBarSelector = document.querySelector('.gazelle-preloader-bar')
  const tlProgress = gsap.timeline({
    paused: true,
    onComplete: () => loadComplete()
  })
  let preloadComplete = false
  let loadedCount = 0
  let loadingProgress = 0

  // Progress the preload timeline
  const loadProgress = () => {
    loadedCount++
    loadingProgress = (loadedCount / preloadFrames)
    tlProgress.progress(loadingProgress)
  }

  // Timeline to play when preload is complete
  const loadComplete = () => {
    document.body.style.overflow = 'auto'

    preloadComplete = true

    gsap.timeline()
      .to(preloaderSelector, { opacity: 0, duration: 1, ease: Power4.easeIn })
      .to(preloaderSelector, { display: 'none', duration: 0 })
  }

  // The preloader timeline (paused by default)
  tlProgress.to(preloaderBarSelector, 1, { width: '100%', ease: Power4.easeInOut })

  const canvas = document.getElementById(canvasId)

  if (!canvas) {
    return
  }

  const context = canvas.getContext('2d')
  let frameURL = `https://cloudinary.pondigital.solutions/pon-digital-solutions/image/upload/w_${document.body.clientWidth},h_${document.body.clientHeight},c_fill,g_center,q_auto:low,f_auto/${frameLocation}`

  canvas.width = document.body.clientWidth
  canvas.height = document.body.clientHeight

  ScrollTrigger.matchMedia({
    // mobile
    '(max-width: 767px)': function () {
      frameURL = `https://cloudinary.pondigital.solutions/pon-digital-solutions/image/upload/w_${document.body.clientWidth},h_${document.body.clientHeight},c_fill,g_xy_center,x_800,y_0,q_auto:low,f_auto/${frameLocation}`
    }
  })

  const frameCount = frames
  const currentFrame = (index) => (
    `${frameURL}-${(index + 1).toString()}`
  )

  const images = []
  const video = {
    frame: 0
  }

  for (let i = 0; i < frameCount; i += 4) {
    const img = new Image()
    img.src = currentFrame(i + offset)
    images.push(img)

    // eslint-disable-next-line
    img.addEventListener('load', () => {
      !preloadComplete && img.complete && img.naturalHeight !== 0 && loadProgress()
    })
  }

  gsap.to(video, {
    frame: (frameCount / 4) - 1,
    snap: 'frame',
    scrollTrigger: {
      trigger: trigger,
      start: 'top top',
      end: sceneEnd,
      scrub: 1,
      pin: true,
      markers: false
    },
    onUpdate: render
  })

  images[0].onload = render

  // Draw frame image on canvas
  function render () {
    const img = images[video.frame]
    let scale = Math.max(canvas.width / img.width, canvas.height / img.height)

    ScrollTrigger.matchMedia({
      '(max-width: 767px)': function () {
        if (!stretch) {
          scale = Math.min(canvas.width / img.width, canvas.height / img.height)
        }
      }
    })

    const x = (canvas.width - (img.width * scale)) / 2
    const y = (canvas.height - (img.height * scale)) / 2

    if (clearCanvas) {
      context.clearRect(0, 0, canvas.width, canvas.height)
    }

    context.drawImage(img, x, y, img.width * scale, img.height * scale)
  }
}
