<template>
  <div :id="id" v-if="specifications.length > 0" class="bg-light-grey px-4 py-[50px] md:py-[75px]" data-testid="specifications" :key="triggerRerender">
    <div class="container">
      <h4 class="text-h4 md:text-h2 text-black font-bold md:max-w-lg md:!leading-normal mb-8 md:mb-24">{{specificationsTitle}}</h4>
      <Accordion v-for="(specification, index) in highlightedSpecs" :key="index"
        isOpen
        hasIcon
        wrapperClass="accordion-wrapper accordion-wrapper__highlight border-y border-medium-dark-grey"
        bodyClass="accordion-body cursor-default py-8 md:mt-0 md:text-base md:mx-0 px-0 md:!p-0 md:flex flex-col relative overflow-visible"
        headerClass="accordion-header !flex-col md:!flex-row md:pl-[50px] md:py-14"
      >
      <template #header>
          <h2 id="highlight-specification-title" class="specification__title text-black font-bold my-14 md:m-0 text-medium-large md:text-large" data-testid="specifications-title">{{specification.title}}</h2>
        </template>
        <template #accordionContent>
          <div v-for="(subcategory, index) in specification.items" :key="index" @click.stop.prevent>
            <div v-if="subcategory.items" class="specification__subcategory-items mb-8 md:pr-8 md:mb-16 md:pt-0 md:mt-0 md:border-none border-t border-medium-dark-grey">
              <p class="specification__subcategory text-blue mb-4">{{subcategory.title}}</p>
              <div v-for="(item, index) in subcategory.items" :key="index" class="mb-4 flex flex-col md:flex-row md:grid-col-2 md:gap-24">
                <div class="flex-[0_0_40%] flex flex-row items-center">
                  <strong class="mr-[15px] text-black md:max-w-[130px]">{{subcategory.title}}</strong>
                  <Tooltip v-if="subcategory.description"  :tooltipSize="13" hasHover tooltipClass="repeat w-4 h-4 z-20 relative">
                    <div v-html="subcategory.description" class="tooltip-content after:content-[''] after:absolute after:top-[100%] after:left-[50%] after:-ml-2 after:border-4 after:border-solid after:border-[#625349_transparent_transparent] bg-light-brown text-white text-tiny p-8 min-w-[250px] absolute z-50 bottom-[140%] -left-[715.5%]"/>
                  </Tooltip>
                </div>
                <div>
                  <span class="text-dark-grey">{{subcategory.value}}</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="specification__subcategory-items flex flex-col md:flex-row md:pr-8 md:mt-0 md:grid-col-2 md:gap-24 py-8 md:py-0 md:pt-0 border-t border-medium-dark-grey md:border-none"
              :class="{ 'md:pt-8': (index !== 0) }">
              <div class="flex-[0_0_40%] flex flex-row items-center">
                <strong class="mr-6 md:mr-3 text-black md:max-w-[130px]">{{subcategory.title}}</strong>
                <Tooltip  v-if="subcategory.description" :tooltipSize="13" hasHover tooltipClass="w-4 h-4 z-20 relative">
                  <div v-html="subcategory.description" class="tooltip-content cursor-text after:content-[''] after:absolute after:top-[100%] after:left-[50%] after:-ml-2 after:border-4 after:border-solid after:border-[#625349_transparent_transparent] bg-light-brown text-white text-tiny p-8 min-w-[250px] absolute z-50 bottom-[140%] -left-[715.5%]"/>
                </Tooltip>
              </div>
              <div>
                <span class="text-dark-grey">{{subcategory.value}}</span>
              </div>
            </div>
          </div>
        </template>
      </Accordion>
      <Accordion v-for="(specification, index) in nonHighlightedSpecs" :key="index"
        hasIcon
        wrapperClass="accordion-wrapper border-b border-medium-dark-grey"
        bodyClass="accordion-body cursor-default mt-8 md:mt-0 md:text-base md:mx-0 px-0 md:!p-0 md:flex flex-col z-10 relative overflow-visible"
        headerClass="accordion-header !flex-col md:!flex-row md:py-14 md:pl-[50px]"
      >
        <template #header>
          <h2 :id="`specification-title-${index}`" class="specification__title font-bold my-14 md:m-0 text-medium-large md:text-large" data-testid="specifications-title">{{specification.title}}</h2>
        </template>
        <template #accordionContent>
          <div v-for="(subcategory, index) in specification.items" :key="index" @click.stop.prevent>
            <div
              v-if="subcategory.items"
              class="specification-subcategory__wrapper space-y-4 py-8 md:pr-8 md:pb-0 md:pt-0 md:mt-0 md:border-none border-t border-medium-dark-grey"
              :class="{'md:mt-16': (index !== 0)}">
              <p class="specification__subcategory text-blue font-bold uppercase text-sm tracking-[1.5px]">{{subcategory.title}}</p>
              <div v-for="(item, index) in subcategory.items" :key="index" class="flex flex-col md:flex-row md:grid-col-2 md:gap-24">
                 <div class="flex-[0_0_40%] flex flex-row items-center">
                      <strong class="mr-6 md:mr-3 text-black md:max-w-[130px]">{{item.title}}</strong>
                    <Tooltip v-if="item.description"  :tooltipSize="13" hasHover tooltipClass="repeat w-4 h-4 z-20 relative">
                      <div v-html="item.description" class="tooltip-content cursor-text after:content-[''] after:absolute after:top-[100%] after:left-[50%] after:-ml-2 after:border-4 after:border-solid after:border-[#625349_transparent_transparent] bg-light-brown text-white text-tiny p-8 min-w-[250px] absolute z-50 bottom-[140%] -left-[715.5%]"/>
                    </Tooltip>
                 </div>
                <div>
                  <span class="text-dark-grey">{{item.value}}</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="specification-subcategory__wrapper flex flex-col pt-8 md:pt-0 md:pr-8 md:pt-0 md:mt-0 md:border-none md:flex-row md:grid-col-2 md:gap-24 border-t border-medium-dark-grey"
              :class="{ 'md:pt-8': (index !== 0) }">
                <div class="flex-[0_0_40%]">
                    <strong>{{subcategory.title}}</strong>
                </div>
                <div>
                  <span>{{subcategory.value}}</span>
                </div>
            </div>
          </div>
        </template>
      </Accordion>
      <div
        v-if="specificationsDisclaimer"
        v-html="specificationsDisclaimer"
        class="pt-5 text-medium-light-black"
      />
      <div v-if="compareButton" class="py-8 flex justify-end" :data-product-id="`${productId}`">
          <Button :text="compareButton.label"
                  :title="compareButton.label"
                  :icon="true"
                  :type="compareButton.type"
                  :url="compareButton.link"
                  :data-compare="dataCompareString"
                  buttonClass="tocompare" />
      </div>
    </div>
  </div>
</template>

<script>
import { Accordion, Button, Tooltip } from '@ponbike/vue-components'
import {mapGetters} from 'vuex'

export default {
  name: 'SpecificationsBlock',
  components: {
    Accordion,
    Button,
    Tooltip
  },
  data() {
    return {
      id: 'specifications',
      rerender: false
    }
  },
  mounted() {
    if (this.specifications.length > 0) {
      this.$store.dispatch('sections/addSection', this.id)
    }
    window.addEventListener('resize', () => {
      this.handleResize()
    })
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.handleResize()
    })
  },
  methods: {
    handleResize() {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth <= 970) {
        this.rerender = true
      }
      else {
        this.rerender = false;
      }
    }
  },
  computed: {
    ...mapGetters('product', [
     'specifications',
     'familyName',
     'name',
     'productId',
     'sku',
     'getUrlByTag',
     'getButtonByTag',
     'data',
     'getContent'
    ]),
    triggerRerender() {
      return this.rerender
    },
    nonHighlightedSpecs() {
      return this.specifications.filter((spec) => spec.title !== 'Highlights')
    },
    highlightedSpecs() {
      return this.specifications.filter((spec) => spec.title === 'Highlights')
    },
    compareUrl() {
      return this.getUrlByTag('product-compare-add')
    },
    compareButton() {
      return this.getButtonByTag('compare')
    },
    dataCompareString() {
      return `{"action": "${this.compareUrl}","data":{"product": "${this.productId}"}}`
    },
    specificationsDisclaimer() {
      return this.getContent('product-specifications-disclaimer')
    },
    specificationsTitle() {
      return [
        this.getContent('specifications_of'),
        this.familyName,
        this.name
      ].join(' ')
    }
  }
}
</script>

<style>
#specifications .tooltip-content p a {
  color: #fff;
}

@media screen(md) {
  .accordion-body:last-child > div:last-child > .specification__subcategory-items {
    margin-bottom: 0;
  }
}

.accordion-body:last-child > div:first-child > .specification__subcategory-items,
.accordion-body:last-child > div:first-child > .specification-subcategory__wrapper {
  border: none;
  padding-top: 0;
}

@media (hover: hover) {
  .accordion-wrapper:hover {
    background-color: theme('colors.darker-grey');
  }
}

.compare-full,
.in-compare {
  background-color: #ffff !important;
  color: #bababa !important;
  opacity: 0.6 !important;
}

</style>
