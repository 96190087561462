<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <button
    class="Hotspot bg-transparent relative group"
    :slot="slotName"
    :data-position="position"
    :data-normal="normal"
    data-visibility-attribute="visible"
    data-testid="hotspot-button"
  >
    <span class="relative flex h-4 w-4 md:h-8 md:w-8">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
      <span class="relative inline-flex rounded-full h-4 w-4 md:h-8 md:w-8 bg-white"></span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      required: true
    },
    normal: {
      type: String,
      required: true
    },
    slotName: {
      type: String,
      required: true
    },
  }
}
</script>
