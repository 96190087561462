<template>
  <div class="glide max-h-[80vh]" :id="id">
    <div class="glide__track max-h-[80vh]" data-glide-el="track">
      <ul class="glide__slides max-h-[80vh]">
        <template v-if="images?.length > 0">
          <li v-for="(slide, index) in images" :key="index" class="glide__slide !h-auto">
            <img
              loading="lazy"
              :data-src="slide.image_url"
              data-transformation="e_trim"
              :alt="slide.title || ''"
              class="w-full portrait:h-[50vh] portrait:object-cover portrait:object-center md:h-full md:object-cover"
              data-testid="carousel-img">
          </li>
        </template>
        <template v-else>
          <slot name="slides" />
        </template>
      </ul>
    </div>
    <div v-if="controls" class="glide__arrows" data-glide-el="controls">
      <button class="glide__arrow glide__arrow--left" title="Back" data-glide-dir="<">
        <span class="vue-icon-arrow-left text-black"></span>
      </button>
      <span>
        {{ currentSlideIndex }} / {{ totalSlides }}
      </span>
      <button class="glide__arrow glide__arrow--right" title="Next" data-glide-dir=">">
        <span class="vue-icon-arrow-right text-black"></span>
      </button>
    </div>
    <div class="hidden tablet:!hidden md:!block absolute w-full h-full top-0 bottom-0">
      <div @click="slideToPrev()" class="absolute w-1/2 h-full top-0 cursor-chevron-left" data-testid="slide-prev"></div>
      <div @click="slideToNext()" class="absolute w-1/2 h-full top-0 left-1/2 cursor-chevron-right" data-testid="slide-next"></div>
    </div>
  </div>
  <div
    v-if="labelHtml"
    id="family-label"
    class="flex justify-center mt-[-58px]"
  >
    <div
      v-html="labelHtml"
      class="relative bg-white rounded py-5 px-8 color-dark-grey text-dark-grey text-center font-bold"
      :class="{'shadow-md': isEclipseBike}"
      data-testid="carousel-label">
    </div>
  </div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css'
import Glide from '@glidejs/glide'
import { mapGetters } from 'vuex'

let glideID = 0

export default {
  name: 'ContentCarousel',
  props: {
    images: {
      type: Array
    },
    settings: {
      type: Object,
      default() {
        return {
          type: 'carousel',
          perView: 1.75,
          focusAt: 'center',
          gap: 6,
          breakpoints: {
            768: {
              perView: 1.25
            }
          }
        }
      }
    },
    labelHtml: {
      type: String
    },
    controls: {
      type: Boolean,
      default: false
    },
    totalSlides: {
      type: Number
    }
  },
  data() {
    glideID += 1
    return {
      id: `glide-${glideID}`,
      carousel: null
    }
  },
  created() {
    this.carousel = new Glide(`#${this.id}`, this.settings)
  },
  mounted() {
    this.carousel.mount()
  },
  computed: {
    ...mapGetters('product', [
      'familyName',
      'isEclipseBike'
    ]),
    currentSlideIndex() {
      return this.carousel.index + 1
    }
  },
  methods: {
    slideToPrev() {
      this.carousel.go('<')
    },
    slideToNext() {
      this.carousel.go('>')
    }
  }
}
</script>

<style scoped>
.glide__slide {
  transform: translate3d(0, 0, 0);
}

.glide__slide--active {
  transform: none;
}

.glide__arrows {
  @apply flex justify-between mt-[24px] text-dark-grey text-sm;
}

.glide__arrow img {
  @apply h-[12px];
}

.glide__arrow--disabled {
  @apply opacity-20;
}

#family-label {
  transform: translateY(29px);
}
</style>
