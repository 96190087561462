<template>
  <div
    id="gazelle-pdp-stickybar"
    ref="stickybar"
    class="bg-light-grey flex justify-between items-center fixed -top-sticky-navbar left-0 right-0 px-[18px] md:px-[50px] py-[10px] bg-grey h-sticky-navbar transition-all duration-200">
    <div>
      <span class="text-dark-grey font-bold !hidden md:!block" data-testid="product-label">
        {{ familyName }} {{ name }}
      </span>
      <div class="!block md:!hidden relative">
        <button class="flex items-center" @click="toggleNav" title="Open menu">
          <span class="text-black text-tiny font-bold" data-testid="dropdown-label">
            {{ directlyToLabel }}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.828"
            height="6.414"
            viewBox="0 0 10.828 6.414"
            class="ml-[10px] w-[8px]"
            :class="{ 'rotate-180': isOpen }">
              <path
                d="m0 0 3.989 4L8 0"
                transform="translate(1.414 1.414)"
                style="fill: none; stroke: #000; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10; stroke-width: 2px;"/>
          </svg>
        </button>
        <ul v-show="isOpen" class="absolute bg-light-grey p-[20px] mt-[10px] space-y-[15px] w-[165px]">
          <li v-for="(item, index) in items" :key="index">
            <a :href="item.anchor" @click="toggleNav" class="text-tiny">
              {{ item.label }}
            </a>
          </li>
          <span v-if="isLocallyEnabled" id="lcly-button-12" class="lcly-wrapper in-dropdown !inline-block md:!hidden">
            <Button
              v-if="secondaryButton && !isPreRelease"
              id="lcly-link-12"
              :url="fallbackUrl"
              :text="secondaryButton.label"
              :title="secondaryButton.label"
              :icon="true"
              :type="secondaryButton.type"
              data-testid="locally-btn" />
            <svg v-if="secondaryButton" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" class="lcly-primary-trigger__icon">
              <path d="M12.95 10.707l0.707-0.707-5.657-5.657-1.414 1.414 4.242 4.243-4.242 4.243 1.414 1.414 4.95-4.95z"></path>
            </svg>
          </span>
          <Button
            v-else-if="secondaryButton && !isPreRelease"
            id="testride-button"
            :text="secondaryButton.label"
            :title="secondaryButton.label"
            :url="secondaryButton.link"
            :target="secondaryButton.open_new_tab ? '_blank' : '_self'"
            :icon="true"
            class="!inline-block md:!hidden"
            type="primary"
            size="tiny" />
        </ul>
      </div>
    </div>
    <div class="flex items-center md:space-x-[50px]">
      <ul class="!hidden md:!flex space-x-[50px]">
        <li v-for="(item, index) in items" :key="index">
          <a :href="item.anchor">
            {{ item.label }}
          </a>
        </li>
      </ul>
      <div v-if="primaryButton || secondaryButton" class="flex items-center space-x-[13px]">
        <span v-if="isLocallyEnabled" id="lcly-button-11" class="lcly-wrapper !hidden md:!block">
          <Button
            v-if="secondaryButton && !isPreRelease"
            id="lcly-link-11"
            :url="fallbackUrl"
            :text="secondaryButton.label"
            :title="secondaryButton.label"
            :icon="true"
            :type="secondaryButton.type"
            data-testid="locally-btn" />
          <svg v-if="secondaryButton" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" class="lcly-primary-trigger__icon">
            <path d="M12.95 10.707l0.707-0.707-5.657-5.657-1.414 1.414 4.242 4.243-4.242 4.243 1.414 1.414 4.95-4.95z"></path>
          </svg>
        </span>
        <Button
          v-else-if="secondaryButton && !isPreRelease"
          id="testride-button"
          :text="secondaryButton.label"
          :title="secondaryButton.label"
          :type="secondaryButton.type"
          :url="secondaryButton.link"
          :target="secondaryButton.open_new_tab ? '_blank' : '_self'"
          :icon="true"
          size="small"
          class="!hidden md:!block" />
        <Button
          v-if="primaryButton && !isPreRelease"
          id="gazelle-pdp-primary-btn"
          :text="primaryButton.label"
          :title="primaryButton.label"
          :type="primaryButton.type"
          :url="primaryButton.link"
          :target="primaryButton.open_new_tab ? '_blank' : '_self'"
          :icon="true"
          size="tiny" />
        <Button
          v-else-if="primaryButton && isHubSpotEnabled && isPreRelease"
          :text="NotifyMeLabel"
          :title="NotifyMeLabel"
          :type="primaryButton.type"
          @click="toggleHubSpotForm"
          :icon="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Button } from '@ponbike/vue-components'
import { createLocallyScript } from '../../../helpers/locally'

export default {
  components: {
    Button
  },
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    const self = this

    if (this.isLocallyEnabled) {
      createLocallyScript({
        id: 11,
        button_text: this.secondaryButton?.label
      })
      createLocallyScript({
        id: 12,
        button_text: this.secondaryButton?.label
      })
    }

    /**
     * Show/hide the StickyNavbar when the Header CTA buttons
     * have touched the top of the viewport
     */
    ['scroll', 'resize'].forEach((event) => {
      window.addEventListener(event, () => {
        const headerButtons = document.getElementById('header-buttons')
        const navbar = document.getElementById('navbar')
        if (!headerButtons || !navbar) {
          return
        }
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        const offsetTop = navbar ? (headerButtons.offsetTop + navbar.scrollHeight) : headerButtons.offsetTop
        const offset = offsetTop - scrollTop
        const topClass = '!top-0'
        const zIndexClass = 'z-sticky-navbar'
        const bodyClass = 'stickybar-visible'

        if (offset <= 0 && !self.$refs.stickybar.classList.contains(topClass)) {
          self.$refs.stickybar.classList.add(topClass, zIndexClass)
          document.body.classList.add(bodyClass)
        } else if (offset > 0 && self.$refs.stickybar.classList.contains(topClass)) {
          self.$refs.stickybar.classList.remove(topClass, zIndexClass)
          document.body.classList.remove(bodyClass)
        }
      })
    })
  },
  computed: {
    ...mapGetters('product', [
      'name',
      'familyName',
      'getContent',
      'getButtonByTag',
      'isLocallyEnabled',
      'getConfigByTag',
      'isHubSpotEnabled',
      'isPreRelease'
    ]),
    ...mapGetters('sections', [
      'sections'
    ]),
    items() {
      let items = [
        {
          anchor: '#overview',
          label: this.getContent('overview_label')
        }
      ]

      if (this.sections.includes('ar-viewer')) {
        items.push({
          anchor: '#ar-viewer',
          label: this.getContent('ar_viewer')
        })
      }

      if (this.sections.includes('specifications')) {
        items.push({
          anchor: '#specifications',
          label: this.getContent('specifications_label')
        })
      }

      return items;
    },
    directlyToLabel() {
      return this.getContent('directly_to_label')
    },
    primaryButton() {
      return this.getButtonByTag('stickybar-one')
    },
    secondaryButton() {
      return this.getButtonByTag('stickybar-two')
    },
    fallbackUrl() {
      return this.getConfigByTag('locally_fallback_store_locator')?.value
    },
    NotifyMeLabel() {
      return this.getContent('notify_me')
    },
  },
  methods: {
    toggleNav() {
      this.isOpen = !this.isOpen
    },
    toggleHubSpotForm(){
      const showStockWarningModalEvent = new CustomEvent('showStockWarningModal:show')
      document.dispatchEvent(showStockWarningModalEvent)
    }
  }
}
</script>

<style>
#gazelle-pdp-stickybar .lcly-wrapper {
  position: relative;
}

#gazelle-pdp-stickybar .lcly-primary-trigger {
  padding: 8px 64px 8px 20px;
  appearance: none;
  border: none;
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#gazelle-pdp-stickybar .in-dropdown .lcly-primary-trigger {
  padding: 7px 20px 7px 10px;
  background-color: theme('colors.blue');
  color: #fff;
}

#gazelle-pdp-stickybar .lcly-primary-trigger:hover {
  background-color: theme('colors.darker-grey');
}

#gazelle-pdp-stickybar .lcly-primary-trigger__icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 20px;
  pointer-events: none;
}

#gazelle-pdp-stickybar .in-dropdown .lcly-primary-trigger__icon {
  fill: #fff;
  right: 2px;
  transform: translateY(-50%) scale(0.75);
}

@media screen(md) {
  #gazelle-pdp-primary-btn {
    padding: 8px 64px 8px 20px;
  }

  #gazelle-pdp-primary-btn .pon-icon {
    right: 20px;
    transform: translateY(-50%) scale(0.9);
  }
}
</style>
