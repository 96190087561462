/* eslint-disable */
import cloneDeep from 'lodash.clonedeep';
import { render } from '../utils/render.js';
var HtmlImageLayer = /** @class */ (function () {
  function HtmlImageLayer(element, userCloudinaryImage, plugins, analyticsOptions) {
    var _this = this;
    this.imgElement = element;
    this.htmlPluginState = { cleanupCallbacks: [], pluginEventSubscription: [] };
    var pluginCloudinaryImage = cloneDeep(userCloudinaryImage);
    render(element, pluginCloudinaryImage, plugins, this.htmlPluginState)
      .then(function () {
        _this.htmlPluginState.pluginEventSubscription.forEach(function (fn) { fn(); });
        _this.imgElement.setAttribute('src', pluginCloudinaryImage.toURL({
          trackedAnalytics: {
            sdkCode: analyticsOptions.sdkCode,
            sdkSemver: analyticsOptions.sdkSemver,
            techVersion: analyticsOptions.techVersion,
          }
        }));
      });
  }
  /**
   * Called when component is updated and re-triggers render
   * @param userCloudinaryImage
   * @param plugins
   * @param analyticsOptions
   */
  HtmlImageLayer.prototype.update = function (userCloudinaryImage, plugins, analyticsOptions) {
    var _this = this;
    var pluginCloudinaryImage = cloneDeep(userCloudinaryImage);
    render(this.imgElement, pluginCloudinaryImage, plugins, this.htmlPluginState)
      .then(function () {
        _this.imgElement.setAttribute('src', pluginCloudinaryImage.toURL({
          trackedAnalytics: {
            sdkCode: analyticsOptions.sdkCode,
            sdkSemver: analyticsOptions.sdkSemver,
            techVersion: analyticsOptions.techVersion,
          }
        }));
      });
  };
  return HtmlImageLayer;
}());
export { HtmlImageLayer };
