<template>
  <div v-if="products?.length > 0" class="container product-overview">
    <h2 v-if="sectionTitle" class="text-center text-[32px] text-black md:text-h2 font-bold mb-[25px] md:mb-[50px] md:w-3/4 xl:w-1/2 mx-auto" data-testid="related-products-title">
      {{ sectionTitle }}
    </h2>
    <div class="justify-center gap-[30px] lg:w-5/6 lg:mx-auto" :class="[layoutType, products.length > 1 && '!hidden']">
      <ProductTile
        v-for="(product, index) in products"
        :key="index"
        lazyLoad
        :imageDataSet="{ transformation: 'e_trim' }"
        :identifier="product.sku"
        :label_text="product?.badge?.text"
        :label_variant="product?.badge?.variant"
        :assets="formatAssets(product.assets)"
        :productName="product.name"
        :category="product.attribute_group"
        :price="product.price.final_price"
        :colors="formatColors(product.colors)"
        :frames="product.frames && formatFrames(product.frames)"
        :buttonPrimaryUrl="getProductUrl(product)"
        :buttonPrimaryText="viewLabel"
        :class="[tileSize]"
        @update="tileUpdated">
        <template #prices>
          <div class="price-wrapper">
            <Prices
              :price="product.price.final_price"
              :base_price="product.price.base_price"
              :monthly_price="product.price.monthly_price"
              :locale="product.price.locale"
              :currency="product.price.currency"
              :price_prefix_label="pricePrefixLabel"
              :monthly_price_label="monthlyPriceLabel"
            />
            <span
              v-if="hasMonthlyPrice(product)"
              :data-item-id="product.id"
              :data-item-name="product.name"
              :data-item-url="product.url"
              class="tooltip-finance-rates vue-icon-info cursor-pointer float-left ml-[5px] text-dark-grey"
            />
          </div>
        </template>
        <template #secondary-button>
          <Button
            v-if="allowCompare && compareButton"
            :text="compareButton.label"
            :title="compareButton.label"
            :url="compareButton.link"
            type="secondary"
            :data-compare="getCompareString(product.id)"
            buttonClass="tocompare" />
        </template>
      </ProductTile>
    </div>
    <div :class="[products.length > 1 ? '!block md:!hidden' : 'hidden']">
      <Carousel :settings="gallerySettings" :controls="true" :totalSlides="products.length">
        <template #slides>
          <ProductTile
            v-for="(product, index) in products"
            :key="index"
            lazyLoad
            :imageDataSet="{ transformation: 'e_trim' }"
            :identifier="product.sku"
            :assets="formatAssets(product.assets)"
            :label_text="product?.badge?.text"
            :label_variant="product?.badge?.variant"
            :frames="product.frames && formatFrames(product.frames)"
            :productName="product.name"
            :category="product.attribute_group"
            :price="product.price.final_price"
            :colors="formatColors(product.colors)"
            :buttonPrimaryUrl="getProductUrl(product)"
            :buttonPrimaryText="viewLabel"
            buttonPrimarySize="tiny"
            :class="[tileSize]"
            @update="tileUpdated">
            <template #prices>
              <div class="price-wrapper">
                <Prices
                  :price="product.price.final_price"
                  :base_price="product.price.base_price"
                  :monthly_price="product.price.monthly_price"
                  :locale="product.price.locale"
                  :currency="product.price.currency"
                  :price_prefix_label="pricePrefixLabel"
                  :monthly_price_label="monthlyPriceLabel"
                />
                <span
                  v-if="hasMonthlyPrice(product)"
                  :data-item-id="product.id"
                  :data-item-name="product.name"
                  :data-item-url="product.url"
                  class="tooltip-finance-rates vue-icon-info cursor-pointer float-left ml-[5px] text-dark-grey"
                />
              </div>
            </template>
            <template #secondary-button>
              <Button
                v-if="allowCompare && compareButton"
                :text="compareButton.label"
                :title="compareButton.label"
                :url="compareButton.link"
                type="secondary"
                :data-compare="getCompareString(product.id)"
                buttonClass="tocompare"
                size="tiny" />
            </template>
          </ProductTile>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ProductTile, Prices, Button } from '@ponbike/vue-components'
import Carousel from '../Carousel/Carousel.vue'
import { updateCloudinaryImages } from '../../../helpers/cloudinary.js'

export default {
  name: 'ProductOverview',
  components: {
    ProductTile,
    Button,
    Prices,
    Carousel
  },
  data() {
    return {
      swatchData: {},
      gallerySettings: {
        type: 'slider',
        perView: 1.1,
        rewind: false,
        focusAt: 0,
        gap: 12
      }
    }
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    allowCompare: {
      type: Boolean,
      default: false
    },
    sectionTitle: {
      type: String
    }
  },
  computed: {
    ...mapGetters('product', [
      'getContent',
      'getUrlByTag',
      'getButtonByTag'
    ]),
    /**
     * Change the tile layout according
     * to the amount of related products
     */
    layoutType() {
      let typeClass

      switch (this.products.length) {
        case 1:
          typeClass = 'md:!flex'
          break
        case 2:
        case 4:
          typeClass = 'md:!grid grid-cols-2'
          break
        case 3:
        case 5:
        case 6:
          typeClass = 'md:!grid grid-cols-3'
          break
        default:
          typeClass = 'md:!flex'
          break
      }

      return typeClass
    },
    /**
     * Change the tile size according
     * to the amount of related products
     */
    tileSize() {
      let sizeClass

      switch (this.products.length) {
        case 1:
        case 2:
        case 4:
          sizeClass = 'one-half md:basis-1/2'
          break
        case 3:
        case 5:
        case 6:
          sizeClass = 'one-third'
          break
        default:
          sizeClass = 'one-half md:basis-1/2'
          break
      }

      return sizeClass
    },
    viewLabel() {
      return this.getContent('view_label')
    },
    monthlyPrice() {
      return this.price.monthly_price
    },
    monthlyPriceLabel() {
      return this.getContent('per_month')
    },
    pricePrefixLabel() {
      return this.getContent('price_label')
    },
    compareUrl() {
      return this.getUrlByTag('product-compare-add')
    },
    compareButton() {
      return this.getButtonByTag('compare')
    }
  },
  methods: {
    hasMonthlyPrice(product) {
      return product.price.monthly_price > 0
    },
    formatColors (colors) {
      if (colors.length === 0) {
        return
      }

      return colors.filter(color => !color.tag_identifier.includes('-bg') && !color.tag_identifier.includes('-text'))
        .map(color => ({
          identifier: color.tag_identifier,
          primary: `rgb(${color.primary_rgb})`,
          secondary: color.secondary_rgb && `rgb(${color.secondary_rgb})`,
          label: color.secondary_label ? `${color.primary_label}/${color.secondary_label}` : color.primary_label
        }))
    },
    formatAssets (assets) {
      let newAssets = {}

      assets.forEach(asset => {
        const tags = asset.tags && asset.tags[0]
          ? [asset.tags[0]]
          : []

        const frame = asset.tags.find(tag => {
          if (tag.includes('frame-')) {
            return true
          }
        })

        if (frame) {
          tags.push(frame.split('frame-')[1])
        }

        newAssets[tags.join('-')] = asset.image_url
      })

      return newAssets
    },
    getProductUrl (product) {
      if (!this.swatchData[product.sku]) {
        return product.url
      }

      const data = []
      if (this.swatchData[product.sku].color) {
        data.push(`color=${this.swatchData[product.sku].color}`)
      }

      if (this.swatchData[product.sku].frame) {
        data.push(`frame=${this.swatchData[product.sku].frame}`)
      }

      return data.length
        ? product.url + '#' + data.join('&')
        : product.url
    },
    formatFrames(frames) {
      return frames?.map(frame => {
        if (!frame.tag_identifier.includes('frame-')) {
          return false
        }

        return frame.tag_identifier.split('frame-')[1]
      }) || []
    },
    tileUpdated(data) {
      this.swatchData[data.id] = data
      updateCloudinaryImages('.product-overview')
    },
    getCompareString(id) {
      return `{"action": "${this.compareUrl}","data":{"product": "${id}"}}`
    }
  }
}
</script>
