<template>
  <div id="overview" class="relative">
    <StructuredData />
    <div
      v-if="isEclipseBike"
      class="relative"
    >
      <PreLoader />
      <Header class="has-swatch" />
      <FamilyVideo :class="{'mb-12': !hasAwards}" />
      <Awards />
      <VideoBlock class="has-swatch" />
      <ARViewer v-if="activeModelUrl" class="has-swatch" />
      <ScrollableScene v-if="scrollable?.file_dir" class="mb-[5px]" />
      <Family />
      <USP />
      <Features class="has-swatch" />
      <CtaBlock v-if="!isPreRelease && isPrivateLease"
                :title="ctaBlockTitle.privateLease"
                :subTitle="ctaBlockSubTitle"
                :privateLeaseContent="ctaBlockContent"
                :primaryButton="ctaBlockPrimaryButton.privateLease"
                :mainImage="ctaBlockImg.privateLease" />
      <Video />
      <SideViewWrapper />
      <MotorInfo
        v-if="!isBikeWithArModel"
        class="has-swatch"
      />
      <AccuInfo class="has-swatch py-24" />
      <DisplayInfo v-if="!isBikeWithArModel" />
      <CtaBlock v-if="!isPreRelease"
                :title="ctaBlockTitle.testRide"
                :primaryButton="ctaBlockPrimaryButton.testRide"
                :secondaryButton="ctaBlockSecondaryButton"
                :mainImage="ctaBlockImg.testRide" />
      <ProductReviews class="pb-12 lg:pb-28" />
      <Specifications />
      <CTA class="has-swatch mt-24" />
      <Accessories class="mt-12 md:mt-0" />
      <RelatedProducts class="mt-12 md:mt-0" />
      <StickyNavbar />
    </div>
    <div
      v-else
      class="relative"
    >
      <Header class="has-swatch" />
      <Family />
      <ScrollableScene v-if="scrollable?.file_dir" />
      <USP class="bg-light-grey" />
      <Gallery class="has-swatch" />
      <ARViewer v-if="activeModelUrl" class="has-swatch" />
      <RotatableWrapper v-else class="has-swatch" />
      <Awards />
      <VideoBlock class="has-swatch mt-[40px] lg:mt-[100px]" />
      <Features class="has-swatch" />
      <CtaBlock v-if="!isPreRelease && isPrivateLease"
                :title="ctaBlockTitle.privateLease"
                :subTitle="ctaBlockSubTitle"
                :privateLeaseContent="ctaBlockContent"
                :primaryButton="ctaBlockPrimaryButton.privateLease"
                :mainImage="ctaBlockImg.privateLease" />
      <SideViewWrapper />
      <Video />
      <MotorInfo class="has-swatch" />
      <AccuInfo class="has-swatch pb-7" />
      <DisplayInfo />
      <ConnectApp class="has-swatch" />
      <CtaBlock v-if="!isPreRelease"
                :title="ctaBlockTitle.testRide"
                :primaryButton="ctaBlockPrimaryButton.testRide"
                :secondaryButton="ctaBlockSecondaryButton"
                :mainImage="ctaBlockImg.testRide" />
      <QuoteBlock/>
      <ProductReviews class="py-12 lg:py-28" />
      <Specifications class="mb-24" />
      <CTA class="has-swatch" />
      <Accessories class="py-24" />
      <RelatedProducts />
      <StickyNavbar />
    </div>
  </div>
</template>

<script>
import PreLoader from '../components/product/PreLoader/PreLoader.vue'
import Header from '../components/product/Header/Header.vue'
import Family from '../components/product/Family/Family.vue'
import FamilyVideo from '../components/product/Family/FamilyVideo.vue'
import ScrollableScene from '../components/product/ScrollableScene/ScrollableScene.vue'
import ARViewer from '../components/product/ARViewer/ARViewer.vue'
import RotatableWrapper from '../components/product/RotatableWrapper/RotatableWrapper.vue'
import Gallery from '../components/product/Gallery/Gallery.vue'
import Specifications from '../components/product/Specifications/Specifications.vue'
import QuoteBlock from '../components/product/QuoteBlock/QuoteBlock.vue'
import ProductReviews from '../components/product/ProductReviews/ProductReviews.vue'
import Awards from '../components/product/Awards/Awards.vue'
import SideViewWrapper from '../components/product/SideView/SideViewWrapper.vue'
import Features from '../components/product/Features/Features.vue'
import CTA from '../components/product/CTA/CTA.vue'
import MotorInfo from '../components/product/MotorInfo/MotorInfo.vue'
import AccuInfo from '../components/product/AccuInfo/AccuInfo.vue'
import DisplayInfo from '../components/product/DisplayInfo/DisplayInfo.vue'
import CtaBlock from '../components/product/CtaBlock/CtaBlock.vue'
import USP from '../components/product/USP/USP.vue'
import Video from '../components/product/Video/Video.vue'
import Accessories from '../components/product/Accessories/Accessories.vue'
import RelatedProducts from '../components/product/RelatedProducts/RelatedProducts.vue'
import StickyNavbar from '../components/product/StickyNavbar/StickyNavbar.vue'
import StructuredData from '../components/product/StructuredData/StructuredData.vue'
import ConnectApp from '../components/product/ConnectApp/ConnectApp.vue'
import VideoBlock from '../components/product/VideoBlocks/VideoBlock.vue'

import { isSSR } from '../helpers/application.js'
import { pushToDataLayer } from '../helpers/gtm.js'
import { mapActions, mapGetters } from 'vuex'
import { initializeCloudinary, transformUrl }  from '../helpers/cloudinary.js'

export default {
  name: 'pdp-application',
  components: {
    PreLoader,
    ConnectApp,
    Header,
    Family,
    FamilyVideo,
    ScrollableScene,
    USP,
    ARViewer,
    RotatableWrapper,
    Gallery,
    Features,
    Awards,
    SideViewWrapper,
    CTA,
    ProductReviews,
    QuoteBlock,
    Specifications,
    MotorInfo,
    AccuInfo,
    DisplayInfo,
    CtaBlock,
    Video,
    Accessories,
    RelatedProducts,
    StickyNavbar,
    StructuredData,
    VideoBlock
  },
  computed: {
    ...mapGetters('product', [
      'getColorByTag',
      'getFrameByTag',
      'getContent',
      'getImageByTags',
      'getButtonByTag',
      'filterImageByTags',
      'price',
      'familyName',
      'name',
      'sku',
      'brand',
      'breadcrumbs',
      'isEbike',
      'ar_model',
      'scrollable',
      'getARModelByTags',
      'isEclipseBike',
      'isBikeWithArModel',
      'isPreRelease',
      'isPrivateLease',
      'hasAwards'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    activeModelUrl() {
      const model = this.getARModelByTags(this.activeColor, this.activeFrame)
      return model?.file_url
    },
    ctaBlockTitle() {
      const privateLease = this.getContent('private_lease_title')
      const testRide = this.getContent('request_configure_bike_description').replace('%s', `${this.familyName} ${this.name}`)
      return {
        privateLease,
        testRide
      }
    },
    ctaBlockSubTitle() {
      return `${this.familyName} ${this.name}`
    },
    ctaBlockContent() {
      return this.getContent('private_lease_description')
    },
    ctaBlockPrimaryButton() {
      const privateLease = this.getButtonByTag('private-lease-one')
      const testRide = this.getButtonByTag('testride-one')
      return {
        privateLease,
        testRide
      }
    },
    awards() {
      const awards = this.filterImageByTags('award')
      const pdpAwards = awards.map(award => transformUrl(award.image_url, ['e_trim']))

      return pdpAwards
    },
    ctaBlockSecondaryButton() {
      return this.getButtonByTag('testride-two')
    },
    ctaBlockImg() {
      const privateLease = this.getImageByTags('private-lease')
      const testRide = this.getImageByTags('testride')
      return {
        privateLease,
        testRide
      }
    }
  },
  mounted() {
    initializeCloudinary()
  },
  created() {
    if (isSSR) {
      return
    }

    this.initializeSwatches()
    document.body.classList.add('gazelle-pdp')
    pushToDataLayer({
      event: 'productDetail',
      ecommerce: {
        currencyCode: this.price.currency,
        detail: {
          products: [{
            name: `${this.familyName} ${this.name}`,
            id: this.sku || '',
            price: this.price.final_price || '',
            brand: this.brand || '',
            category: this.breadcrumbs ? this.breadcrumbs[1].label : '',
            variant: this.activeColor || '',
            position: 1,
            dimension2: null,
            dimension4: null,
            dimension16: this.isEbike ? 'E-bike' : 'Non E-bike'
          }]
        }
      }
    })
    window.activeFrame = this.activeFrame
    window.activeColor = this.activeColor

    if (this.hasAwards) {
      let hubspotData = window.hubspotData || {}

      hubspotData.pdpAwards = this.awards

      window.hubspotData = hubspotData
    }
  },
  methods: {
    ...mapActions('swatches', [
      'setActiveColor',
      'setActiveFrame'
    ]),
    /**
     * If there is a matching color available in the URL hash, we use this color to set the active swatch.
     * The color should match the tag identifier of the colors. Example: #color=color-pistache-green
     */
    initializeSwatches() {
      const location = window.location.search ? window.location.search : window.location.hash.substring(1)
      const searchParams = new URLSearchParams(location)
      const colorIdentifier = searchParams.get('color')
      const frameIdentifier = searchParams.get('frame')
      if (colorIdentifier && this.getColorByTag(colorIdentifier)) {
        this.setActiveColor(colorIdentifier)
      }
      if (frameIdentifier) {
        let newFrameIdentifier = `frame-${frameIdentifier}`

        if (frameIdentifier.includes('frame-')) {
          newFrameIdentifier = `${frameIdentifier}`
        }

        if (newFrameIdentifier && this.getFrameByTag(newFrameIdentifier)) {
          this.setActiveFrame(newFrameIdentifier)
        }
      }
    }
  }
}
</script>
<style>
  img {
    min-height: 1px;
  }
</style>
