import { updateCloudinaryImages } from '../../helpers/cloudinary.js'

const initialState = () => ({
  activeColor: '',
  activeFrame: 'frame-low'
})

const getters = {
  activeColor: state => state.activeColor,
  activeFrame: state => state.activeFrame
}

const actions = {
  setActiveColor ({ commit }, color) {
    updateCloudinaryImages('.has-swatch')

    commit('setActiveColor', color)
  },
  setActiveFrame ({ commit }, color) {
    updateCloudinaryImages('.has-swatch')

    commit('setActiveFrame', color)
  }
}

const mutations = {
  setActiveColor (state, color) {
    // eslint-disable-next-line
    state.activeColor = color
  },
  setActiveFrame (state, frame) {
    // eslint-disable-next-line
    state.activeFrame = frame
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
