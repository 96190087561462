<template>
  <section v-if="review.summary.count > 0" class="container xl:w-5/6 xl:mx-auto" :id="id" data-testid="product-reviews">
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <h4 class="font-bold max-w-[570px] text-medium-large text-center mb-5 lg:text-h4 lg:text-left lg:mb-0">{{reviewLabel}}</h4>
      <div class="hidden lg:!block">
        <StarRating
          v-if="review.summary.count"
          lazyLoad
          hasTrustpilot
          wrapperClass="items-center lg:items-left"
          :trustpilotImgUrl="trustpilotLogoUrl"
          :trustpilotPoweredBy="reviewPoweredBy"
          :trustpilotUrl="trustpilotUrl"
          :scoreSize="79"
          :maxScoreSize="53"
          :star_size="29"
          :score="review.summary.rating"
          :total="review.summary.count"
          :total_suffix="reviewSuffix"
          :total_suffix_singular="reviewSuffixSingular"
          starratingWrapperClass="mb-2"
          scoreClass="leading-[52px] font-bold"
          maxScoreClass="top-[-0.3em] opacity-50"
          :starSpacing="12" />
      </div>
      <div class="lg:hidden">
        <StarRating
          v-if="review.summary.count"
          lazyLoad
          hasTrustpilot
          wrapperClass="items-center lg:items-left"
          :trustpilotImgUrl="trustpilotLogoUrl"
          :trustpilotPoweredBy="reviewPoweredBy"
          :trustpilotUrl="trustpilotUrl"
          :scoreSize="52"
          :maxScoreSize="35"
          :star_size="19"
          :score="review.summary.rating"
          :total="review.summary.count"
          :total_suffix="reviewSuffix"
          :total_suffix_singular="reviewSuffixSingular"
          starratingWrapperClass="mb-2"
          scoreClass="leading-[52px] font-bold"
          maxScoreClass="top-[-0.3em] opacity-50"
          :starSpacing="8" />
      </div>
    </div>
    <div v-if="review.items.length > 0" class="flex flex-col md:flex-row md:justify-between mt-[30px] md:mt-[100px] gap-5">
      <div class="w-full md:basis-1/3 lg:basis-1/4">
        <AdvancedRating :ratings="mappedRatings" @update:rating="showFilteredRating" bar_color="#004a99" />
        <Button v-if="selectedStars" class="mt-5" :text="clearFilters" :icon="true" @click="resetReviews()" size="small" type="secondary" />
      </div>
      <div class="w-full md:basis-2/3 lg:basis-3/4 relative h-[200px]" v-if="isLoadingFilteredStars">
        <span class="loader"></span>
      </div>
      <div v-else class="basis-2/3 lg:basis-3/4">
        <div v-for="(review, index) in reviews" :key="index" class="pt-5 pb-10 border-t border-grey-lines" data-testid="single-product-review">
          <h4 class="text-medium text-black font-bold mb-1">{{ review.title }}</h4>
          <span class="block text-dark-grey text-tiny mb-3">{{ review.name }} - {{ review.date }}</span>
          <StarRating :score="review.score" :star_size="20" class="mb-3" />
          <p class="text-dark-brown">{{ review.description }}</p>
          <div v-if="review.reply" class="border-l-[3px] border-blue bg-light-grey p-4 mt-5 ml-4 md:ml-16">
            <h4 class="text-medium font-bold mb-2">{{ reviewReplyFrom }} {{ review.reply.name }}</h4>
            <p class="text-dark-grey">{{ review.reply.description }}</p>
          </div>
        </div>
        <div v-if="reviews.length !== numberOfReviews" class="flex h-auto justify-center">
          <button class="font-bold space-x-2.5 flex items-center bg-white" @click.prevent="loadMore()" data-testid="more-review-reveal">
            <span>{{ reviewViewMore }}</span>
            <svg v-if="isLoading" class="animate-spin" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
              <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
              <path d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"/>
            </svg>
            <span v-else class="text-[8px] vue-icon-arrow-down text-black"></span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AdvancedRating, StarRating, Button } from '@ponbike/vue-components'
import { mapGetters } from 'vuex'
import apiClient from '../../../../v1/services/api-client.js'

export default {
  name: 'ProductReviews',
  components: {
    AdvancedRating,
    StarRating,
    Button
  },
  data() {
    return {
      id: 'product-reviews',
      reviews: [],
      numberOfReviews: null,
      page: 2,
      selectedStars: 0,
      isLoading: false,
      isLoadingFilteredStars: false
    }
  },
  created() {
    this.reviews = [...this.review.items]
    this.numberOfReviews = this.review.summary.count
  },
  computed: {
    ...mapGetters('product', [
      'review',
      'getContent',
      'getUrlByTag',
      'familyName',
      'name'
    ]),
    mappedRatings() {
      let ratings = []
      let total = 0

      for (const star of this.review.summary.star_info) {
        total += star.count
        ratings.push({
          star_number: star.star,
          number_of_reviews: star.count
        })
      }

      ratings.map(rating => {
        rating.percentage = (rating.number_of_reviews / total) * 100
      })

      return ratings.slice().reverse()
    },
    reviewSuffix() {
      return this.getContent('review_suffix')
    },
    reviewSuffixSingular() {
      return this.getContent('review_suffix_singular')
    },
    reviewViewMore() {
      return this.getContent('review_view_more')
    },
    reviewReplyFrom() {
      return this.getContent('review_reply_from')
    },
    clearFilters() {
      return this.getContent('clear_filters')
    },
    trustpilotLogoUrl() {
      return 'https://cloudinary.pondigital.solutions/pon-digital-solutions/image/upload/v1657785456/dmp.pon.bike/trustpilot-logo_q2myq1.svg'
    },
    trustpilotUrl() {
      return this.getUrlByTag('trustpilot-reviews')
    },
    reviewPoweredBy() {
      return this.getContent('review_powered_by')
    },
    reviewLabel() {
      return this.getContent('review_label')
       .replace('%s', `${this.familyName} ${this.name}`)
    }
  },
  methods: {
    showFilteredRating(rating) {
      if (rating.number_of_reviews >= 1) {
        this.selectedStars = rating.star_number
        this.page = 1
        this.numberOfReviews = rating.number_of_reviews

        this.loadMore()
      }
    },

    async loadMore() {
      if (this.isLoading || this.isLoadingFilteredStars) {
        return
      }

      if (this.selectedStars && this.page <= 1) {
        this.isLoadingFilteredStars = true
      } else {
        this.isLoading = true
      }

      const apiUrl = this.review.api_url.replace(':page', this.page + '?stars=' + this.selectedStars)
      const response = await apiClient.get(apiUrl)

      if (this.selectedStars && this.page <= 1) {
        this.reviews = response?.data
        this.isLoadingFilteredStars = false
      } else {
        response?.data?.forEach(review => {
          this.reviews.push(review)
        })
        this.isLoading = false
      }

      this.page++
    },
    resetReviews() {
      let activeFilter = document.getElementsByClassName('pon-advanced-rating active')

      activeFilter[0].classList.remove('active')
      this.reviews = [...this.review.items]
      this.selectedStars = 0
      this.numberOfReviews = this.review.summary.count
      this.page = 2
    },
  }
}
</script>

<style>
.pon-advanced-rating__reviews {
  @apply basis-[100px] shrink-0 md:pr-8;
}

.loader {
  left: 50%;
  top: 50%;
  width: 100px;
  height: 58px;
  position: absolute;
  background: url('/src/frontend/assets/bike-frame.svg') no-repeat center center;
  background-size: contain;
  transform: translateY(-50%) translateX(-50%);
}

.loader::before {
  left: -16px;
  animation: loading-wheel 0.5s linear 0s forwards infinite;
}

.loader::after {
  left: 64px;
  animation: loading-wheel 0.5s linear 0s forwards infinite;
  animation-duration: 0.5s;
}

.loader::before,
.loader::after {
  top: 31px;
  position: absolute;
  content: '';
  width: 52px;
  height: 52px;
  background: url('/src/frontend/assets/bike-wheel.svg') no-repeat center center;
  background-size: contain;
}

.pon-advanced-rating {
  padding: 3px 5px;
  border-radius: 5px;
}

.pon-advanced-rating:not(.pon-advanced-rating--none) {
  cursor: pointer;
}

.pon-advanced-rating:not(.pon-advanced-rating--none):hover,
.pon-advanced-rating.active {
  background-color: theme('colors.light-grey');
}

.pon-advanced-rating.active .pon-progress-bar__bg,
.pon-advanced-rating:not(.pon-advanced-rating--none):hover .pon-progress-bar__bg {
  background-color: theme('colors.white');
}

.pon-advanced-rating.active .pon-advanced-rating__reviews,
.pon-advanced-rating:not(.pon-advanced-rating--none):hover .pon-advanced-rating__reviews {
  color: theme('colors.dark-grey');
}

.pon-advanced-rating.active {
  pointer-events: none;
}

.pon-advanced-rating__star-number {
  @apply basis-[20px] text-center;
}

@keyframes loading-wheel {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
}
</style>
