<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-if="rotatableImages.length"
    class="relative"
  >
    <ThreeSixty
      lazyLoadFallback
      :assets="rotatableImageUrls"
      :colorData="selectedColor"
      :altText="altText"
    />
    <a
      v-if="activeModelUrl"
      href="#ar-viewer"
      class="flex flex-col md:gap-2 justify-center items-center absolute top-0 right-0 rounded-full bg-white border-2 border-blue text-blue w-16 h-16 md:w-20 md:h-20 transition duration-300 hover:bg-blue-hover"
    >
      <span class="vue-icon-ar text-medium-large" />
      <span class="font-bold md:text-medium">
        AR
      </span>
    </a>
  </div>
  <Loader v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import { ThreeSixty, Loader } from '@ponbike/vue-components'
import { transformUrl } from '../../../helpers/cloudinary.js'
import { addActiveSwatchesTags } from '../../../helpers/image.js'

export default {
  components: {
    ThreeSixty,
    Loader
  },
  computed: {
    ...mapGetters('product', [
      'getImageByTags',
      'uniqueColorIdentifiers',
      'filterImageByTags',
      'getARModelByTags'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    rotatableImages() {
      let filteredImages = []
      if (this.isThreeSixty) {
        const tags = addActiveSwatchesTags(['360'])
        filteredImages = this.filterImageByTags(...tags)
      } else {
        const tags = addActiveSwatchesTags(['gallery', this.preferredAngle])
        filteredImages = [this.getImageByTags(...tags)]
      }

      return filteredImages
    },
    rotatableImageUrls() {
      const filteredImages = this.rotatableImages.map(image => image.image_url)

      return filteredImages.length ? filteredImages.map(image => transformUrl(image)) : []
    },
    selectedColor() {
      return {
        tag_identifier: this.activeColor
      }
    },
    altText() {
      return this.rotatableImages[0]?.title || ''
    },
    isThreeSixty() {
      const tags = addActiveSwatchesTags(['360'])

      return this.filterImageByTags(...tags).length > 1
    },
    preferredAngle() {
      const angle = 'angle-front-side'
      const coloredImages = this.uniqueColorIdentifiers.map(color => this.getImageByTags(color, angle)).filter(color => Object.keys(color).length !== 0)
      if (this.uniqueColorIdentifiers.length === coloredImages.length) {
        return angle
      }

      return 'angle-side-view'
    },
    activeModelUrl() {
      const model = this.getARModelByTags(this.activeColor, this.activeFrame)
      return model?.file_url
    }
  }
}
</script>
