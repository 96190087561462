<template>
  <section v-if="carouselImages.length >= 3" class="relative">
    <Carousel :images="carouselImages" :labelHtml="familyLabel" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Carousel from '../Carousel/Carousel.vue'

export default {
  name: 'ProductFamily',
  components: {
    Carousel
  },
  computed: {
    ...mapGetters('product', [
      'brand',
      'familyName',
      'bikesInFamily',
      'getContent',
      'getUrlByTag',
      'filterImageByTags',
      'getImageByTags'
    ]),
    video() {
      return this.getImageByTags('family-video')
    },
    carouselImages() {
      return this.filterImageByTags('family')
    },
    bikeSuffixLabel() {
      return this.getContent('version_suffix')
    },
    bikeSuffixSingularLabel() {
      return this.getContent('version_suffix_singular')
    },
    bikeCountLabel() {
      return this.getContent('family_bike_count_label')
    },
    familyUrl() {
      return this.getUrlByTag('family')
    },
    familyLabel() {
      return this.bikeCountLabel
        .replace('%s', `<a href="${this.familyUrl}" target="_blank" class="underline hover:underline hover:text-light-black">${this.familyName}</a>`)
        .replace('%d', this.bikesInFamily)
        .replace('%f', this.bikesInFamily === 1 ? this.bikeSuffixSingularLabel : this.bikeSuffixLabel)
    }
  }
}
</script>
