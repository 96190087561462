<template>
  <div v-if="mainImage" class="w-full md:h-[500px]"
       :class="{ 'pb-0 md:pb-4 md:mb-0 md:py-12 md:px-4': isSideView }"
  >
    <img
      class="m-auto md:object-contain md:h-full"
      :src="transformedImageUrl"
      data-testid="image-main"
      :alt="mainImage.title || ''"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addActiveSwatchesTags } from '../../../helpers/image.js'
import { transformUrl } from '../../../helpers/cloudinary.js'

export default {
  name: 'ProductImage',
  computed: {
    ...mapGetters('product', [
      'uniqueFilteredColorIdentifiers',
      'getImageByTags',
      'filterImageByTags',
      'name',
      'familyName'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    preferredAngle() {
      const angle = 'angle-front-side'
      const coloredImages = this.uniqueFilteredColorIdentifiers.map(color => this.getImageByTags(color, angle)).filter(color => Object.keys(color).length !== 0)
      if (this.uniqueFilteredColorIdentifiers.length === coloredImages.length) {
        return angle
      }

      return 'angle-side-view'
    },
    isSideView() {
      return this.preferredAngle === 'angle-side-view'
    },
    mainImage() {
      const tags = addActiveSwatchesTags([this.preferredAngle])

      return this.getImageByTags(...tags)
    },
    transformedImageUrl() {
      return transformUrl(this.mainImage.image_url, ['c_scale', 'e_trim'])
    }
  }
}
</script>
