import { createSSRApp, createApp, h } from 'vue'
import './index.css'
import App from './App.vue'
import { getDevPostData } from './helpers/dev'
import { isSSR, setupStoreData } from './helpers/application'
import store from './store/index.js'

export default async function (args) {
  const config = isSSR ? args.initialConfig : getDevPostData()

  await setupStoreData(config)

  const rootComponent = {
    render: () => h(App)
  }

  const app = isSSR ? createSSRApp(rootComponent) : createApp(rootComponent)

  app.use(store)

  return {
    app
  }
}
