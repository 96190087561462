<template>
  <section class="scrollable-section bg-cover min-h-[100vh] relative mt-12 bg-grey">
    <canvas
      id="scrollable"
      class="w-full h-full block my-0 mx-auto"
    />
    <div>
      <div
        id="scrollable-content-1"
        class="absolute top-auto md:top-[10vw] bottom-[10vw] md:bottom-auto left-0 md:left-auto right-0 md:right-[5vw] text-black md:w-[36vw] bg-white/90 rounded-sm p-4 md:p-0 md:bg-transparent m-2 lg:m-0"
      >
        <h3 class="mt-0 mb-2 text-large md:text-h2 leading-small">
          {{ getContent('eclipse_scrollable_content_one_title') }}
        </h3>
        <h4 class="m-0 text-medium md:text-h4 font-semibold md:font-normal">
          {{ getContent('eclipse_scrollable_content_one_description') }}
        </h4>
      </div>
      <div
        id="scrollable-content-2"
        class="absolute top-[20vw] md:top-[10vw] left-0 md:left-auto right-0 md:right-[5vw] text-black md:w-[36vw] bg-white/90 rounded-sm p-4 md:p-0 md:bg-transparent m-2 lg:m-0"
      >
        <h3 class="mt-0 mb-2 text-large md:text-h2 leading-small">
          {{ getContent('eclipse_scrollable_content_two_title') }}
        </h3>
        <h4 class="m-0 text-medium md:text-h4 font-semibold md:font-normal">
          {{ getContent('eclipse_scrollable_content_two_description') }}
        </h4>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from 'gsap/dist/gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { mapGetters } from 'vuex'
import { createScrollableScene } from '../../../helpers/scrollable.js'
import { isSSR } from '../../../helpers/application.js'

export default {
  computed: {
    ...mapGetters('product', [
      'scrollable',
      'getContent'
    ])
  },
  mounted() {
    if (isSSR || typeof window?.matchMedia !== 'function') {
      return
    }

    gsap.registerPlugin(ScrollTrigger)

    const contentOne = '#scrollable-content-1'
    const contentTwo = '#scrollable-content-2'
    let offset = 0
    let delay = 2
    let sceneEnd = '+=3500'

    // Skip the first 90 frames on smaller devices
    if (window.matchMedia('(max-width: 970px)').matches) {
      offset = 90
      delay = 0
      sceneEnd = '+=2000'
    }

    gsap.timeline({
      defaults: {
        duration: 120
      },
      scrollTrigger: {
        trigger: '.scrollable-section',
        scrub: true,
        start: '+=0',
        end: sceneEnd,
        pin: false
      }
    })
    .from(contentOne, { opacity: 0, delay, duration: 5, ease: 'none' })
    .from(contentOne, { y: 25, duration: 5, ease: 'none' }, 0)
    .to(contentOne, { y: -25, duration: 5, ease: 'none' })
    .to(contentOne, { opacity: 0, duration: 5, ease: 'none' }, '<')
    .from(contentTwo, { opacity: 0, duration: 10, ease: 'none' })
    .from(contentTwo, { y: 25, duration: 20, ease: 'none' }, '<')

    createScrollableScene(
      'scrollable',
      510,
      [this.scrollable.file_dir, this.scrollable.file_prefix].join(''),
      '.scrollable-section',
      sceneEnd,
      true,
      false,
      offset
    )
  }
}
</script>
