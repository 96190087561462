<template>
  <div>
    <BikeFrameSelect
      v-if="frames && frames.length"
      :frames="frames"
      :disabledFrames="[]"
      :selectedFrame="activeFrameIdentifier"
      @update="updateFrame"
    />
    <ColorSelect
      v-if="colors && colors.length"
      :colors="colors"
      :disabledColorIdentifiers="disabledColors"
      :selectedColorIdentifier="activeColor"
      :selectedColorLabel="activeColorLabel"
      :showTooltip="true"
      @update="updateColor"
      class="mt-6"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ColorSelect, BikeFrameSelect } from '@ponbike/vue-components'
import { pushToDataLayer } from '../../../helpers/gtm.js'

export default {
  name: 'ProductSwatches',
  components: {
    ColorSelect,
    BikeFrameSelect
  },
  computed: {
    ...mapGetters('product', [
      'availableFilteredColors',
      'availableFrames',
      'getImageByTags'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    frames() {
      return this.availableFrames?.map(frame => frame.split('frame-')[1])
    },
    activeFrameIdentifier() {
      return this.activeFrame
        ? this.activeFrame.split('frame-')[1]
        : ''
    },
    activeColorLabel() {
      const activeColor = this.availableFilteredColors.find(color => color.tag_identifier === this.activeColor)

      if (!activeColor || !activeColor.primary_label) {
        return false
      }

      return activeColor.primary_label
    },
    colors() {
      return this.availableFilteredColors?.map(color => {
        const newColor = {
          identifier: color.tag_identifier,
          primary: `rgb(${color.primary_rgb})`,
          label: color.primary_label
        }

        if (color.secondary_rgb) {
          newColor.secondary = `rgb(${color.secondary_rgb})`
        }

        return newColor
      })
    },
    disabledColors() {
      return this.activeFrame
        ? this.getDisabledColorsByFrame(this.activeFrame)
        : []
    }
  },
  methods: {
    ...mapActions('swatches', [
      'setActiveColor',
      'setActiveFrame',
    ]),
    updateColor(color) {
      this.setActiveColor(color)
      pushToDataLayer({
        event: 'productSpecsUse',
        eventCategory: 'Product',
        eventAction: 'use specs selector bike',
        eventLabel: color
      })
      window.activeColor = this.activeColor
      window.activeFrame = this.activeFrame
    },
    updateFrame(frame) {
      frame = `frame-${frame}`
      this.setActiveFrame(frame)

      const disabledColors = this.getDisabledColorsByFrame(frame)
      if (disabledColors.includes(this.activeColor)) {
        const enabledColors = this.getEnabledColorsByFrame(frame)
        this.setActiveColor(enabledColors[0])
      }

      pushToDataLayer({
        event: 'productSpecsUse',
        eventCategory: 'Product',
        eventAction: 'use specs selector bike',
        eventLabel: frame
      })
      window.activeFrame = this.activeFrame
      window.activeColor = this.activeColor
    },
    getEnabledColorsByFrame(frame) {
      const colors = []
      this.colors.forEach(color => {
        if (Object.keys(this.getImageByTags(frame, color.identifier)).length !== 0) {
          colors.push(color.identifier)
        }
      })
      return colors
    },
    getDisabledColorsByFrame(frame) {
      const colors = []
      this.colors.forEach(color => {
        if (Object.keys(this.getImageByTags(frame, color.identifier)).length === 0) {
          colors.push(color.identifier)
        }
      })
      return colors
    }
  }
}
</script>

<style>
.pon-frame {
  border-bottom-width: 1px;
}

.pon-frame svg {
  width: 39px;
  height: 24px;
}

.pon-color .icon-check {
  width: 20px;
  height: 20px;
}

.pon-color-wrapper.has-tooltip::before {
  @apply top-[-59px] text-tiny;
}

.pon-color-wrapper.has-tooltip::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f2f2f2;
}

.pon-color-wrapper.has-tooltip::before,
.pon-color-wrapper.has-tooltip::after {
  @apply hidden md:block;
}
</style>
