<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div class="container xl:h-screen flex flex-col py-14 overflow-x-hidden relative">
    <h3 class="text-display-2-mobile md:text-display-2 font-bold text-center m-0 text-white">
      {{ getContent('side_view_title') }}
    </h3>
    <model-viewer
      v-if="isMounted && activeModelUrl"
      id="side-view-model"
      :alt="getContent('model_viewer_title')"
      :src="activeModelUrl"
      shadow-intensity="1"
      touch-action="pan-y"
      :camera-orbit="`${cameraOrbit}deg 75deg 4m`"
      camera-target="0.07273m 0.64m -0.00002364m"
      field-of-view="22.22deg"
      disable-tap
      disable-zoom
      class="w-full xl:grow h-[30vh] sm:h-[42vh] md:h-[58vh] lg:h-[70vh] xl:h-full"
    >
      <div class="hidden" slot="progress-bar" />
      <SideViewHotspot
        slot-name="hotspot-1"
        position="0.31590369097944837m 1.1330124325159276m -0.07888984069028489m"
        normal="-0.636078294329787m 0.7716245223438797m 2.977905502975481e-7m"
        @click.prevent="setActiveContent(eclipseHotSpotContentDisplay)"
      />
      <SideViewHotspot
        slot-name="hotspot-2"
        position="0.08682885055846302m 0.7058673577774779m -0.06219175905394536m"
        normal="-0.8625132880344989m 0.49244032401107574m 0.11650474347332063m"
        @click.prevent="setActiveContent(eclipseHotSpotContentBattery)"
      />
      <SideViewHotspot
        slot-name="hotspot-3"
        position="-0.08417557337796598m 0.2629112131922992m -0.03192258815943947m"
        normal="0.034453473683345684m 0.3594354523897432m 0.932533706477429m"
        @click.prevent="setActiveContent(eclipseHotSpotContentMotor)"
      />
    </model-viewer>
    <div
      v-if="!modelViewerHasLoaded"
      class="absolute inset-0 flex items-center justify-center"
    >
      <Loader />
    </div>
    <p
      v-if="activeContent"
      class="!block bg-white shadow-card rounded-sm text-base text-left p-4 relative md:w-1/2 md:m-auto w-full"
    >
      <span
        ref="animation"
        class="absolute inline-flex inset-0 bg-white opacity-50"
      />
      <span class="relative" v-html="activeContent" />
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Loader } from '@ponbike/vue-components'
import SideViewHotspot from './SideViewHotspot.vue'
import { isSSR } from '../../../helpers/application.js'

export default {
  name: 'SideView',
  components: {
    SideViewHotspot,
    Loader
  },
  data() {
    return {
      isMounted: false,
      cameraOrbit: 0,
      activeContent: '',
      modelViewerHasLoaded: false
    }
  },
  mounted() {
     this.isMounted = true
     this.loadComponent()

     if (isSSR) {
      return
     }

     setTimeout(() => {
      document.querySelector('#side-view-model')?.addEventListener('load', () => {
        this.modelViewerHasLoaded = true
      })

    }, 100)

     /**
      * Bind the camera's rotation to the mouse X-axis
      */
     window.addEventListener('mousemove', (event) => {
      if (event.target.id === 'side-view-model') {
        const localX = event.clientX - event.target.offsetLeft;
        const min = -15
        const max = 15
        const percentage = ((localX / event.target.clientWidth) * 100).toFixed()
        const mappedValue = this.mapRange(percentage, 0, 100, min, max)

        this.cameraOrbit = mappedValue
      }
     })
   },
  computed: {
    ...mapGetters('product', [
      'getContent',
      'getARModelByTags'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    activeModelUrl() {
      const model = this.getARModelByTags(this.activeColor, this.activeFrame)
      return model?.file_url
    },
    eclipseHotSpotContentDisplay() {
      return this.getContent('eclipse_hotspot_content_display')
    },
    eclipseHotSpotContentBattery() {
      return this.getContent('eclipse_hotspot_content_battery')
    },
    eclipseHotSpotContentMotor() {
      return this.getContent('eclipse_hotspot_content_motor')
    },
  },
  methods: {
    loadComponent() {
      require('@google/model-viewer/dist/model-viewer-umd')
     },
    mapRange(value, inMin, inMax, outMin, outMax) {
      /**
       * Maps a percentage to a custom min/max value, ex:
       * -15 = 0%, 15 = 100%
       */
      return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    },
    setActiveContent(content) {
      this.activeContent = content

      if (this.$refs.animation) {
        this.$refs.animation.classList.add('animate-ping')
        setTimeout(() => {
          this.$refs.animation.classList.remove('animate-ping')
        }, 500)
      }
    }
  }
}
</script>
