<template>
  <div v-if="motorTitle && motorDescription">
    <section class="bg-[#2f2f2f] hidden lg:!block">
      <div class="pt-[50px] md:pt-[144px] text-center px-[36px]">
        <h2 class="text-white font-bold lg:max-w-[970px] max-w-[600px] m-auto !leading-normal text-h3 lg:text-h1 pb-9 md:pb-16">
          {{ tagLine }}
        </h2>
      </div>
        <div :style="[{backgroundImage:`url(${imageUrl})`, backgroundPosition: 'right top', backgroundRepeat: 'no-repeat',
          backgroundSize: '58.3%'}]" class="lg:min-h-[80vh] relative xl:pb-[150px]">
          <div class="container content-container">
          <div class="flex flex-wrap items-center lg:min-h-[80vh]">
            <div class="w-full flex flex-col p-[32px] lg:w-5/12 md:flex bg-[#fff] lg:bg-transparent">
              <h3 class="text-black text-h2 leading-normal lg:text-white font-bold">{{ motorTitle }}</h3>
              <p class="text-black lg:text-white text-base font-normal" >{{ motorDescription }}</p>
            </div>
          </div>
        </div>
       <div class="absolute right-0 top-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
      </div>
    </section>
    <section>
      <div class="lg:hidden bg-[#2f2f2f]">
        <div class="pt-[50px] md:pt-[144px] text-center px-[36px]">
          <h2 class="text-white font-bold lg:max-w-[970px] max-w-[600px] m-auto !leading-normal text-h3 lg:text-h1 pb-9 md:pb-16">
            {{ tagLine }}
          </h2>
        </div>
        <div class="relative">
          <div>
            <img loading="lazy" :data-src="motorInfo.image_url" :alt="motorInfo.title || ''" data-transformation="e_trim" />
          </div>
          <div class="container content-container">
            <div class="text-center lg:text-left flex flex-col items-center justify-center px-4 py-12 lg:items-start grid col-span-1 lg:col-span-5 xl:col-span-4">
              <h2 class="text-white font-bold text-[32px] lg:text-h2 lg:pt-[240px] leading-none md:leading-large mb-5">
                {{ motorTitle }}
              </h2>
              <p class="text-white text-base font-normal max-w-[471px] lg:max-w-full">
                {{ motorDescription }}
              </p>
            </div>
          </div>
          <div class="absolute right-0 top-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'

export default {
  name: 'MotorInfo',
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getImageByTags',
      'motor',
      'getContent',
      'availableFilteredColors',
      'battery'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),

    motorInfo() {
      let motor = this.getImageByTags('motor', 'usp', this.activeColor)

      if(!Object.keys(motor).length) {
        motor = this.getImageByTags('motor', 'usp')

        this.availableFilteredColors.every((color) => {
          const tempGalleryImages = this.filterImageByTags(
            'motor',
            'usp',
            color.tag_identifier
          )

          if (tempGalleryImages.length >= 1) {
            motor = tempGalleryImages[0];
            return false
          }

          return true
        })
      }
      return motor
    },
    imageUrl() {
      if (!this.motorInfo || Object.keys(this.motorInfo).length === 0) {
        return ''
      }

      return transformUrl(this.motorInfo.image_url, ['e_trim'])
    },
    tagLine() {
      return this.battery?.battery_tagline
    },
    motorTitle() {
      return this.motor?.motor_title
    },
    motorDescription() {
      return this.motor?.motor_description
    }
  }
}
</script>

<style scoped>
.content-container::before,
.content-container::after {
  display: none;
}

.gradient {
  background: linear-gradient(to top, rgba(47, 47, 47, 0%), rgba(47, 47, 47));
}
</style>
