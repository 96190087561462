<template>
  <div v-if="batteryHeader && batteryDescription" class="bg-dark-grey">
    <section
      v-if="!isEclipseBike || !isBikeWithArModel"
      class="hidden lg:!block"
      :class="{'mb-32' : batterySpecifications.length === 1}"
    >
        <div :style="[{backgroundImage:`url(${imageUrl})`, backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat',
          backgroundSize: '58.3%'}]" class="lg:min-h-[80vh] relative">
          <div class="container content-container">
          <div class="flex flex-wrap items-center lg:min-h-[80vh]">
            <div class="w-full flex flex-col p-[32px] lg:w-5/12 md:flex bg-[#fff] lg:bg-transparent">
              <h3 class="text-black text-h2 leading-normal lg:text-white font-bold">{{ batteryHeader }}</h3>
              <p class="text-black lg:text-white text-base font-normal" >{{ batteryDescription }}</p>
            </div>
          </div>
        </div>
       <div class="absolute right-0 bottom-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
      </div>
    </section>
    <section
      v-if="!isEclipseBike || !isBikeWithArModel"
      :class="{'mb-32' : batterySpecifications.length === 1}"
    >
      <div class="lg:hidden">
        <div>
          <div class="relative">
            <img
              loading="lazy"
              :data-src="motorInfo.image_url"
              data-transformation="e_trim"
              :alt="motorInfo.title"/>
            <div class="absolute right-0 bottom-0 w-full gradient h-[100px] md:h-[200px] z-10"></div>
          </div>
          <div class="container content-container">
            <div class="text-center lg:text-left flex flex-col items-center justify-center px-4 py-12 lg:items-start grid col-span-1 lg:col-span-5 xl:col-span-4">
              <h2 class="text-white font-bold text-[32px] lg:text-h2 lg:pt-[240px] leading-none md:leading-large mb-5">
                {{ batteryHeader }}
              </h2>
              <p class="text-white text-base font-normal max-w-[471px] lg:max-w-full">
                {{ batteryDescription }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <h3 v-if="batterySpecifications.length > 1" class="text-h4 mb-16 text-white px-4 md:px-0">
          {{batteryTableTitle}}
        </h3>
        <div class="relative w-full">
          <div @scroll="moveGradient" class="flex flex-row border-b border-grey-lines/[.7] mb-7 overflow-x-auto overflow-y-hidden md:overflow-hidden">
            <div
              :class="{'absolute': batterySpecifications.length > 1}"
              class="flex flex-col top-0 left-3.5 md:static w-auto"
            >
              <div class="opacity-0 py-[5px] md:mr-[126px] h-[52px] md:leading-[52px] min-w-[150px]"/>
              <div
                v-for="(label,index) in batteryLabels"
                :key="index"
                :class="
                  [
                    {'hidden': index >= 3 && isHidden},
                    {'hidden': label == null },
                    {'hidden': `${label}` === getContent('battery_price')}
                  ]"
                class="text-white font-bold h-[52px] md:leading-[52px] min-w-[150px]"
              >
                <span
                  :class="{'flex': batterySpecifications.length === 1}"
                  class="text-sm md:text-base md:flex flex-col justify-center h-full"
                >
                  {{ label }}
                </span>
              </div>
            </div>
            <div
              class="flex flex-row w-screen z-10"
              :class="{'justify-between' : batterySpecifications.length > 2}"
            >
              <div
                v-for="(spec, index) in batterySpecifications"
                :key="index"
                class="flex flex-col shrink-0 md:items-center mr-2.5 md:mr-[63px] ml-3.5 lg:mr-[126px] md:mt-0 w-auto"
              >
                  <div
                    :class="[spec.battery_code]"
                    class="py-[5px] md:px-[12px] text-white font-bold h-[52px]"
                  >
                    {{ batteryTitle }} {{ spec.battery_watt_hour }}
                  </div>
                  <div
                    v-for="(value,key,index) in spec"
                    :key="key"
                    class="text-white"
                    :class="{
                      'hidden' : key === 'battery_price',
                      'min-w-[111px]' : key === 'battery_price' && value.base_price === 0
                    }"
                  >
                    <span
                      v-if="key !== 'battery_code' && key !== 'battery_name' && key !== 'battery_price' && batteryLabels.includes(getContent(key))"
                      :class="{'hidden': index >= 7 && isHidden}"
                      class="h-[52px] flex flex-col justify-center m-0"
                    >
                      {{ value || '-' }}
                    </span>
                    <template
                      v-if="key === 'battery_price' && !isHidden && value.base_price != 0"
                    >
                      <span class="h-[52px] flex flex-col justify-center m-0">
                        <Price
                          :price="value.base_price"
                          :currency="value.currency"
                          :locale="value.locale"
                        />
                      </span>
                    </template>
                    <template
                      v-else-if="key === 'battery_price' && !isHidden && includedWithBicylclePrice != ''"
                    >
                       <span class="h-[52px] flex flex-col justify-center m-0">
                          {{ includedWithBicyclePrice }}
                      </span>
                    </template>
                  </div>
              </div>
            </div>
          </div>
          <div
            v-if="batterySpecifications.length > 1"
            ref="specificationGradient"
            :style="{
              opacity: gradientOpacity
            }"
            class="right-[-1px] side-gradient absolute bottom-0 w-[200px] h-full z-50 pointer-events-none xs:hidden"
          />
        </div>
        <div
          id="toggle-specifications"
          class="toggle-specifications w-full text-center text-white cursor-pointer flex flex-row justify-center items-center"
          @click="toggleShowMore">
          <p id="toggle-specifications-label" class="toggle-specifications__label text-white mb-0 mr-2">{{isHidden ? checkSpecifications : showLessSpecifications}}</p>
          <svg id="toggle-specifications-icon" class="toggle-specifications__icon" xmlns="http://www.w3.org/2000/svg" width="10.828" height="6.414" viewBox="0 0 10.828 6.414" :class="{'rotate' : !isHidden}">
            <path d="m0 0 3.989 4L8 0" transform="translate(1.414 1.414)" style="fill: none;stroke: #fff;stroke-linecap: round;stroke-linejoin: round;stroke-miterlimit: 10;stroke-width: 2px;"/>
          </svg>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'
import { Price } from '@ponbike/vue-components'

export default {
  name: 'AccuInfo',
  components: {
    Price
  },
  data() {
    return {
      isHidden: true,
      gradientOpacity: 1
    }
  },
  methods: {
    toggleShowMore() {
      this.isHidden = !this.isHidden
    },
   moveGradient ({ target: { scrollLeft }}) {
      // Slowly fade out the gradient on the right side based on the scroll position
      this.gradientOpacity = 1 - scrollLeft / 100;
    }
  },
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getImageByTags',
      'battery',
      'familyName',
      'getContent',
      'availableFilteredColors',
      'batterySpecifications',
      'isEclipseBike',
      'isBikeWithArModel'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    motorInfo() {
      let battery = this.getImageByTags('battery', 'usp', this.activeColor)
      if(!Object.keys(battery).length) {
        battery = this.getImageByTags('battery', 'usp')

        this.availableFilteredColors.every((color) => {
          const tempGalleryImages = this.filterImageByTags(
            'battery',
            'usp',
            color.tag_identifier
          )

          if (tempGalleryImages.length >= 1) {
            battery = tempGalleryImages[0];
            return false
          }

          return true
        })
      }
      return battery
    },
    imageUrl() {
      if (!this.motorInfo || Object.keys(this.motorInfo).length === 0) {
        return ''
      }

      return transformUrl(this.motorInfo.image_url, ['e_trim'])
    },
    tagLine() {
      return this.battery?.battery_tagline
    },
    batteryHeader() {
      return this.battery?.battery_header
    },
    batteryDescription() {
      return this.battery?.battery_description
    },
    batteryTableTitle() {
      return this.getContent('find_out_about_batteries')
        .replace('%s', this.familyName)
    },
    checkSpecifications() {
      return this.getContent('check_specifications')
    },
    showLessSpecifications() {
      return this.getContent('show_less_specifications')
    },
    includedWithBicyclePrice() {
      return this.getContent('included_with_bicycle_price')
    },
    batteryTitle(){
      return this.getContent('battery')
    },
    batteryInfo() {
      let batteryInfoArray = []

      this.batterySpecifications.map((battery) => {
        for (const [key, value] of Object.entries(battery)) {
          let label = this.getContent(key) !== key ? this.getContent(key) : 'battery_name'

          if (batteryInfoArray[label] === undefined) {
            batteryInfoArray[label] = []
          }

          batteryInfoArray[label].push(value)
        }
      })
        return {...batteryInfoArray}
    },
    batteryLabels() {
      return Object.entries(this.batteryInfo).map(item => {
        if (item[1].length !== 0 && item[1].some(value => value !== "") && item[0] !== 'battery_name') {
          return item[0];
        }
      }).filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    }
  }
}
</script>

<style scoped>
.content-container::before,
.content-container::after {
  display: none;
}

.gradient {
  background: linear-gradient(to bottom, rgba(47, 47, 47, 0%), rgba(47, 47, 47));
}

.side-gradient {
  background: linear-gradient(to right, rgba(47, 47, 47, 0%), rgba(47, 47, 47));
}

.side-gradient-left {
  background: linear-gradient(to left, rgba(47, 47, 47, 0%), rgba(47, 47, 47));
}

.rotate {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
</style>
