import { isSSR } from './application'
import store from '../store'

export const createLocallyScript = async (config) => {
  if (isSSR) {
    return
  }

  const getConfigByTag = store.getters['product/getConfigByTag']

  const locallyConfig = {
    id: config.id,
    company_name: getConfigByTag('locally_company_name')?.value,
    company_id: getConfigByTag('locally_company_id')?.value,
    lang: store.getters['product/locale']?.country_language,
    no_link: getConfigByTag('locally_no_link')?.value,
    button_text: config.button_text
  }

  const locallyQuery = Object.keys(locallyConfig)
    .reduce((previousValue, currentValue) => {
      previousValue.push(`${encodeURIComponent(currentValue)}=${encodeURIComponent(locallyConfig[currentValue])}`)
      return previousValue
    }, [])
    .join('&')

  const locallyEndpoint = `${getConfigByTag('locally_widget_url')?.value}?${locallyQuery}`

  const locallyScript = document.createElement('script')
  locallyScript.type = 'text/javascript'
  locallyScript.src = locallyEndpoint
  locallyScript.async = 'async'

  document.head.appendChild(locallyScript)
}
