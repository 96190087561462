<template>
  <section v-if="title && (primaryButton || secondaryButton)" class="my-[50px] sm:my-[75px]">
    <div class="sm:container">
      <div class="flex flex-col sm:flex-row sm:justify-between">
        <div class="px-[18px] sm:px-0 text-center sm:text-left mb-6 sm:mb-0 sm:basis-4/12 pb-0 sm:py-28 md:py-52">
          <h3
            class="text-[32px] sm:leading-[52px] font-bold sm:text-h3 text-black"
            data-testid="title"
            :class="subTitle ? 'mb-0' : 'mb-5'">
            {{ title }}
          </h3>
          <h2
            v-if="subTitle"
            class="text-[42px] mt-0 font-light"
            data-testid="subtitle">
            {{ subTitle }}
          </h2>
          <p
            v-if="privateLeaseContent"
            data-testid="content">
            {{ privateLeaseContent }}
          </p>
          <div class="flex flex-col xs:block mt-10 xs:mt-0 sm:mt-11">
            <Button
              v-if="primaryButton && !isPreRelease"
              :text="primaryButton.label"
              :title="primaryButton.label"
              :type="primaryButton.type"
              :url="primaryButton.link"
              :target="primaryButton.open_new_tab ? '_blank' : '_self'"
              :icon="true"
              data-testid="primary-btn"
              class="xs:mr-4 sm:mt-4 sm:mr-0 lg:mr-4 lg:mt-0 text-left" />
            <Button
              v-if="secondaryButton"
              :text="secondaryButton.label"
              :title="secondaryButton.label"
              :type="secondaryButton.type"
              :url="secondaryButton.link"
              :target="secondaryButton.open_new_tab ? '_blank' : '_self'"
              :icon="true"
              data-testid="secondary-btn"
              class="mt-4 xs:mt-6 lg:mt-0 !text-black text-left" />
          </div>
        </div>
        <div class="h-[280px] w-full sm:h-[auto] sm:basis-7/12">
          <img
            v-if="mainImage"
            loading="lazy"
            :data-src="mainImage.image_url"
            alt="" class="h-full w-full object-cover"
            data-testid="image" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Button } from '@ponbike/vue-components'
export default {
  name: 'CtaBlock',
  props: {
    title: String,
    subTitle: String,
    primaryButton: Object,
    secondaryButton: Object,
    mainImage: Object,
    privateLeaseContent: String,
  },
  components: {
    Button
  }
}
</script>
