<template>
  <section id="gazelle-pdp-header" class="container mb-16 md:mb-32 relative" data-testid="gazelle-pdp-header">
    <Breadcrumbs v-if="breadcrumbs" class="absolute top-4 left-8 md:top-[18px] md:left-[18px]" />
    <div class="flex flex-row gap-x-default md:items-center md:justify-between pt-16 md:pt-32 px-4 md:px-0 min-h-[500px]">
      <div class="hidden relative md:!block md:w-5/12 lg:w-6/12">
        <Badge
          v-if="badge"
          class="absolute top-0 text-[14px] z-10"
          :variant="badge.variant"
        >
          {{ badge.text }}
        </Badge>
        <Rotatable v-if="isEclipseBike" />
        <Image v-else />
      </div>
      <div
        class="flex flex-col w-full md:w-6/12 lg:w-5/12"
      >
        <a href="#product-reviews" class="order-1">
          <StarRating
            v-if="review.summary.count > 0"
            :star_size="16" :score="review.summary.rating"
            :total="review.summary.count"
            :total_suffix="reviewSuffix"
            :total_suffix_singular="reviewSuffixSingular"
            :starSpacing="4"
            class="mb-[20px]" />
        </a>
        <h1 class="text-h3 text-black leading-small lg:text-h1 font-bold break-normal m-0 order-2" data-testid="header-title">
          {{ familyName }}
          <span class="text-h4 lg:text-h2 font-normal block mt-2.5 md:mt-4 mb-0 md:mb-5" data-testid="header-subtitle">{{ name }}</span>
        </h1>
        <div
          class="md:hidden relative mt-5 order-3"
        >
          <Badge
            v-if="badge"
            class="sm:absolute top-0 mb-5 sm:mb-0 text-[12px] sm:text[16px] z-10"
            :variant="badge.variant"
          >
            {{ badge.text }}
          </Badge>
          <div :class="[isEclipseBike ? 'block mb-10' : 'flex portrait:max-w-[33vh]']"
               class="mx-auto md:px-16 md:max-w-none w-full"
          >
            <Rotatable v-if="isEclipseBike" />
            <Image v-else />
          </div>
        </div>
        <p class="text-dark-grey mb-5 md:my-0 order-5 md:order-4" data-testid="header-description" v-html="shortDescription" />
        <div class="my-5 md:mt-10 order-4 md:order-5">
          <Swatches class="mr-2.5"/>
        </div>
        <div class="price-wrapper order-6">
          <Prices v-if="finalPrice"
                  :currency="currency"
                  :locale="locale"
                  :price="finalPrice"
                  :base_price="basePrice"
                  :monthly_price="monthlyPrice"
                  :price_prefix_label="pricePrefixLabel"
                  :monthly_price_label="monthlyPriceLabel"
          />
          <span
            v-if="hasMonthlyPrice"
            :data-item-id="productId"
            :data-item-name="`${familyName} ${name}`"
            :data-item-url="productUrl"
            :data-item-configurable="true"
            class="tooltip-finance-rates vue-icon-info cursor-pointer float-left ml-[5px] text-dark-grey"
          />
        </div>
        <div
          v-if="primaryButton || secondaryButton"
          id="header-buttons"
          class="flex flex-col space-y-[13px] xs:space-y-0 xs:space-x-[13px] mt-[28px] xs:flex-row order-7 h-auto"
        >
          <span v-if="isLocallyEnabled && secondaryButton" id="lcly-button-13" class="lcly-wrapper">
            <Button
              id="lcly-link-13"
              :url="fallbackUrl"
              :text="secondaryButton.label"
              :title="secondaryButton.label"
              :icon="true"
              :type="secondaryButton.type"
              data-testid="locally-btn" />
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" class="lcly-primary-trigger__icon">
              <path d="M12.95 10.707l0.707-0.707-5.657-5.657-1.414 1.414 4.242 4.243-4.242 4.243 1.414 1.414 4.95-4.95z"></path>
            </svg>
          </span>
          <Button
            v-else-if="secondaryButton && !isPreRelease"
            :text="secondaryButton.label"
            :title="secondaryButton.label"
            :type="secondaryButton.type"
            :url="secondaryButton.link"
            :target="secondaryButton.open_new_tab ? '_blank' : '_self'"
            :icon="true" />
          <Button
            v-if="primaryButton && !isPreRelease"
            :text="primaryButton.label"
            :title="primaryButton.label"
            :type="primaryButton.type"
            :url="primaryButton.link"
            :target="primaryButton.open_new_tab ? '_blank' : '_self'"
            :icon="true" />
          <Button
            v-else-if="primaryButton && isHubSpotEnabled && isPreRelease"
            :text="keepMePostedLabel"
            :title="keepMePostedLabel"
            :type="primaryButton.type"
            @click="toggleHubSpotForm"
            :icon="true" />
        </div>
      </div>
    </div>
    <div class="hidden sm:!block text-center">
      <div class="pt-8">
        <span class="text-xs vue-icon-arrow-down text-black block py-3 animate-bounce"></span>
        <span class="pt-2 font-normal text-xs text-black uppercase">{{ScrollDownLabel}}</span>
      </div>
    </div>
  </section>
</template>

<script>
import Rotatable from '../Rotatable/Rotatable.vue'
import Image from './Image.vue'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import Swatches from '../Swatches/Swatches.vue'
import { Prices, StarRating, Button, Badge } from '@ponbike/vue-components'
import { mapGetters } from 'vuex'
import { createLocallyScript } from '../../../helpers/locally'
import { addActiveSwatchesTags } from '../../../helpers/image.js'

export default {
  name: 'ProductHeader',
  components: {
    Breadcrumbs,
    Rotatable,
    Image,
    Swatches,
    Prices,
    StarRating,
    Button,
    Badge
  },
  async mounted() {
    if (this.isLocallyEnabled) {
     await createLocallyScript({
       id: 13,
       button_text: this.secondaryButton?.label
     })
    }
  },
  computed: {
    ...mapGetters('product', [
      'availableFilteredColors',
      'breadcrumbs',
      'name',
      'review',
      'price',
      'familyName',
      'shortDescription',
      'getContent',
      'filterImageByTags',
      'getButtonByTag',
      'isLocallyEnabled',
      'isHubSpotEnabled',
      'isPreRelease',
      'getConfigByTag',
      'getImageByTags',
      'isEclipseBike',
      'badge',
      'productId',
      'getUrlByTag'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    sideViewImage() {
      const tags = addActiveSwatchesTags(['angle-side-view'])

      return this.getImageByTags(...tags)
    },
    galleryImages() {
      return this.filterImageByTags('gallery')
    },
    reviewSuffix() {
      return this.getContent('review_suffix')
    },
    reviewSuffixSingular() {
      return this.getContent('review_suffix_singular')
    },
    finalPrice() {
      return this.price.final_price
    },
    basePrice() {
      return this.price.base_price
    },
    monthlyPrice() {
      return this.price.monthly_price
    },
    pricePrefixLabel() {
      return this.getContent('price_label')
    },
    monthlyPriceLabel() {
      return this.getContent('per_month')
    },
    keepMePostedLabel() {
      return this.getContent('keep_me_posted')
    },
    ScrollDownLabel() {
      return this.getContent('scroll')
    },
    locale() {
      return this.price.locale
    },
    hasMonthlyPrice() {
      return this.monthlyPrice > 0
    },
    currency() {
      return this.price.currency
    },
    primaryButton() {
      return this.getButtonByTag('header-one')
    },
    secondaryButton() {
      return this.getButtonByTag('header-two')
    },
    fallbackUrl() {
      return this.getConfigByTag('locally_fallback_store_locator')?.value
    },
    productUrl() {
      return this.getUrlByTag('product-page')
    }
  },
  methods: {
    toggleHubSpotForm(){
      const showStockWarningModalEvent = new CustomEvent('showStockWarningModal:show')
      document.dispatchEvent(showStockWarningModalEvent)
    }
  }
}
</script>

<style>
.pon-btn .pon-btn--default,
.pon-btn,
.pon-color-wrapper.has-tooltip {
  border-radius: theme('rounded.s') !important;
}

.pon-star-rating__star {
  position: relative;
  top: -2px;
}

.pon-prices.has-finance .pon-prices__regular {
  font-size: 14px;
  color: theme('colors.brown-grey');
}

.price-wrapper .monthly {
  float: left;
}

.pon-prices .monthly .pon-price,
.pon-prices .monthly .pon-prices__label {
  font-size: 14px;
}

#gazelle-pdp-header .lcly-wrapper {
  position: relative;
}

#gazelle-pdp-header .lcly-primary-trigger {
  width: 100%;
  padding: 12px 64px 12px 20px;
  appearance: none;
  border: none;
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: theme('colors.light-grey');
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#gazelle-pdp-header .lcly-primary-trigger:hover {
  background-color: theme('colors.darker-grey');
}

#gazelle-pdp-header .lcly-primary-trigger__icon {
  width: 16px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 20px;
  pointer-events: none;
}
</style>
