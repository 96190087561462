<template>
  <section v-if="galleryImages.length > 1" class="mt-[6px] mb-0 md:mt-32">
    <div class="!hidden md:!block">
      <Gallery hasZoom lazyLoad :assetData="galleryImages" :altText="familyName" wrapper-class="pon-gallery container"/>
    </div>
    <div class="md:!hidden">
      <Carousel :images="galleryImages" :settings="gallerySettings"/>
    </div>
  </section>
</template>

<script>
import Carousel from '../Carousel/Carousel.vue'
import { Gallery } from '@ponbike/vue-components'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductGallery',
  components: {
    Carousel,
    Gallery
  },
  data() {
    return {
      gallerySettings: {
        type: 'slider',
        perView: 1.2,
        rewind: false,
        focusAt: 0,
        gap: 6
      }
    }
  },
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getImageByTags',
      'familyName',
      'availableFilteredColors',
    ]),

    ...mapGetters('swatches', [
      'activeColor'
    ]),
    galleryImages() {
      let galleryImages = this.filterImageByTags('atmosphere', this.activeColor)
      if(!galleryImages.length) {
        galleryImages = this.filterImageByTags('atmosphere')

        this.availableFilteredColors.every((color) => {
            const tempGalleryImages = this.filterImageByTags(
              'atmosphere',
              color.tag_identifier
            )

            if (tempGalleryImages.length >= 1) {
              galleryImages = tempGalleryImages
              return false
            }

            return true
          }
        )
      }
      return galleryImages
    }
  }
}
</script>

<style>
.pon-gallery::after,
.pon-gallery::before {
  display: none;
}

.pon-gallery__img,
#glide-2 .glide__slide {
  background-color: theme('colors.light-grey');
}

.pon-expandable-image:hover {
  cursor: url('../../../assets/cursor-plus.svg'), auto;
}

.pon-expandable-image.expanded {
  background: rgba(0, 0, 0, 90%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  cursor: default;
}

.pon-expandable-image.expanded.isZooming {
  background: transparent;
}

.pon-expandable-image.expanded > img {
  height: auto;
}

.pon-expandable-image-close-button {
  visibility: hidden;
  line-height: 1;
  color: theme('colors.black');
  background-color: theme('colors.white');
  opacity: 0.5 !important;
  font-size: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pon-expandable-image-close-button.is-shown {
  visibility: visible;
}

.pon-expandable-image-close-button:hover {
  opacity: 1 !important;
}

.pon-expandable-image-close-icon::before,
.pon-expandable-image-close-icon::after {
  top: 22px;
  left: 29px;
  background-color: theme('colors.black');
}

</style>
