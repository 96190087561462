<template>
  <section>
    <ProductOverview
      v-if="accessories && accessories.length"
      :products="accessories"
      :sectionTitle="sectionTitle"/>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductOverview from '../ProductOverview/ProductOverview.vue'

export default {
  name: 'ProductAccessories',
  components: {
    ProductOverview
  },
  computed: {
    ...mapGetters('product', [
      'accessories',
      'familyName',
      'getContent'
    ]),
    sectionTitle() {
      return this.getContent('accessories_label')
        .replace('%s', this.familyName)
        .replace('%d', this.accessories.length === 1 ? this.accessorySuffixSingularLabel : this.accessorySuffixLabel)
    },
    accessorySuffixLabel() {
      return this.getContent('accessory_suffix')
    },
    accessorySuffixSingularLabel() {
      return this.getContent('accessory_suffix_singular')
    }
  }
}
</script>

<style>
.pon-product-tile {
  border-radius: theme('rounded.m') !important;
}
</style>
