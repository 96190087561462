import { createStore } from 'vuex'
import app from './modules/app'
import product from './modules/product'
import swatches from './modules/swatches'
import sections from './modules/sections'
import modal from './modules/modal'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    app,
    product,
    swatches,
    sections,
    modal
  },
  strict: debug
})
