<template>
  <Pdp v-if="currentApp === 'pdp'"/>
</template>

<script>
import Pdp from './applications/Pdp.vue'

export default {
  name: 'App',
  components: {
    Pdp
  },
  computed: {
    currentApp() {
      return this.$store.getters['app/app']
    }
  }
}
</script>

