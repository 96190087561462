import store from '../store'

export const addActiveColorTag = (tags) => {
  if (store.getters['swatches/activeColor']) {
    tags.push(store.getters['swatches/activeColor'])
  }

  return tags
}

export const addActiveFrameTag = (tags) => {
  if (store.getters['swatches/activeFrame']) {
    tags.push(store.getters['swatches/activeFrame'])
  }

  return tags
}

export const addActiveSwatchesTags = (tags) => {
  const newTags = addActiveColorTag(tags)

  return addActiveFrameTag(newTags)
}
