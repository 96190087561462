<template>
  <div v-if="isConnected" class="bg-slate-blue pt-[75px] lg:pt-[150px]">
    <section class="container max-w-[100%] lg:max-w-screen-xl px-0 lg:px-5 mb-12 md:mb-32">
      <div class="flex flex-wrap md:justify-between">
        <div class="w-full h-full px-8 lg:px-4 flex justify-center lg:w-6/12 md:mx-auto">
          <div class="app overflow-hidden w-full max-w-[300px] sm:max-w-[470px] h-[410px] sm:h-[710px] border-light-black bg-[#f1f1f6] border-t-[10px] border-l-[10px] border-r-[10px] sm:border-t-[15px] sm:border-l-[15px] sm:border-r-[15px] rounded-t-[40px] sm:rounded-t-[60px]">
            <div class="app-header relative bg-white p-[10px] sm:p-[15px] h-[240px] sm:h-[370px] pb-0 rounded-t-[30px] sm:rounded-t-[45px]">
              <div class="w-100 h-[20px] sm:h-[30px] bg-phone-status-bar mt-1 bg-no-repeat bg-contain" />
              <div class="app-icons w-100 h-auto my-3 sm:my-5 flex justify-between">
                <span class="w-[20px] h-[20px] sm:w-[25px] sm:h-[25px] block bg-phone-question-mark bg-no-repeat bg-contain" />
                <span class="w-[20px] h-[20px] sm:w-[25px] sm:h-[25px] block bg-phone-plus-mark bg-no-repeat bg-contain" />
              </div>
              <h4 class="font-bold text-[16px] sm:text-large">{{ familyName + ' ' + name }}</h4>
              <img loading="lazy" :data-src="productImageUrl" :alt="productImageAltText" class="max-w-[80%] absolute bottom-[-25px] sm:bottom-[-40px] left-0 right-0 m-auto" />
            </div>
            <div class="app-footer px-2 sm:px-3 mt-8">
              <div class="app-content w-100 h-[300px] bg-phone-content bg-no-repeat bg-contain sm:bg-cover" />
            </div>
          </div>
        </div>
        <div class="w-full shadow-top lg:shadow-none flex-col py-14 sm:py-20 lg:py-0 px-8 lg:px-4 lg:w-5/12 text-white">
          <h3 class="font-bold text-[32px] sm:text-h2 leading-normal">{{ connectHeader }}</h3>
          <p class="mt-2 text-medium">{{ connectDescription }}</p>
          <div class="app-usps mt-10">
            <ol class="text-base space-y-9 pl-[27px]">
              <li class="pl-10">{{ connectUspOne }}</li>
              <li class="pl-10">{{ connectUspTwo }}</li>
              <li class="pl-10">{{ connectUspThree }}</li>
              <li class="pl-10">{{ connectUspFour }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { addActiveSwatchesTags } from '../../../helpers/image.js'

export default {
  computed: {
    ...mapGetters('product', [
      'name',
      'familyName',
      'getImageByTags',
      'uniqueFilteredColorIdentifiers',
      'filterImageByTags',
      'isConnected',
      'getContent'
    ]),
    ...mapGetters('swatches', [
      'activeColor'
    ]),
    preferredAngle() {
      const angle = 'angle-side-view'
      const coloredImages = this.uniqueFilteredColorIdentifiers.map(color => this.getImageByTags(color, angle)).filter(color => Object.keys(color).length !== 0)

      if (this.uniqueFilteredColorIdentifiers.length === coloredImages.length) {
        return angle
      }

      return 'angle-front-side'
    },
    productImage() {
      const tags = addActiveSwatchesTags([this.preferredAngle])
      const productImage = this.getImageByTags(...tags)

      if (!productImage || Object.keys(productImage).length === 0) {
        return ''
      }

      return productImage
    },

    productImageUrl() {
      if (!this.productImage) {
        return ''
      }

      return this.productImage.image_url
    },
    productImageAltText() {
      if (!this.productImage) {
        return ''
      }

      return this.productImage.title
    },
    connectHeader() {
      return this.getContent('connect_header')
    },
    connectDescription() {
      return this.getContent('connect_description')
    },
    connectUspOne() {
      return this.getContent('connect_usp_one')
    },
    connectUspTwo() {
      return this.getContent('connect_usp_two')
    },
    connectUspThree() {
      return this.getContent('connect_usp_three')
    },
    connectUspFour() {
      return this.getContent('connect_usp_four')
    }
  }
}
</script>

<style scoped>
.app-usps ol {
  /* Tailwind does not have support for list-style-image */
  list-style-image: url('/src/frontend/assets/check-icon-white.png');
}
</style>
