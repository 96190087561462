const initialState = () => ({
  is3DControlsModalOpen: false,
  isARModalOpen: false
})

const getters = {
  is3DControlsModalOpen: state => state.is3DControlsModalOpen,
  isARModalOpen: state => state.isARModalOpen
}

const actions = {
  toggleARModal ({ commit }) {
    commit('toggleARModal')
  },
  toggle3DControlsModal ({ commit }) {
    commit('toggle3DControlsModal')
  }
}

const mutations = {
  toggleARModal (state) {
    // eslint-disable-next-line
    state.isARModalOpen = !state.isARModalOpen
  },
  toggle3DControlsModal (state) {
    // eslint-disable-next-line
    state.is3DControlsModalOpen = !state.is3DControlsModalOpen
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
