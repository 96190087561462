import { Actions, CloudinaryImage, CloudinaryVideo } from '@cloudinary/url-gen'
import { responsive, HtmlImageLayer } from '../thirdparty/@cloudinary/html'

const { Delivery } = Actions
const { dpr } = Delivery
const defaultPlugins = [responsive({ steps: 1 })]

export const initializeCloudinary = () => {
  prepareImages()
  setImageSources(getCloudinaryImages())
}

export const prepareImages = () => {
  const images = document.querySelectorAll('img[data-src]')

  for (let i = 0; i < images.length; ++i) {
    images[i].classList.add('cloudinary-image')
    images[i].setAttribute('id', `cloudinary-image-${i}`)
  }
}

export const makeCloudinaryAsset = (url, transformation, isVideo = false) => {
  const assetId = url.includes('%image_options%/') ? url.split('%image_options%/')[1] : url.split('upload/')[1]
  const options = [
    {
      cloudName: 'pon-digital-solutions'
    },
    {
      secureDistribution: 'cloudinary.pondigital.solutions',
      secure: true
    }
  ]
  const asset = isVideo ? new CloudinaryVideo(assetId, ...options) : new CloudinaryImage(assetId, ...options)

  asset.quality('auto')
  asset.format('auto')
  asset.addAction(dpr('auto'))
  asset.addTransformation(transformation)

  return asset
}

export const isSkipTransform = (url) => !url || url.includes('?_a=') || url.includes('%3F_a%3D')

export const transformUrl = (url, transformation, isVideo = false) => {
  // If it includes the ?_a query in the url, then the url is already transformed
  if (isSkipTransform(url)) {
    return url
  }
  const cloudinaryAsset = makeCloudinaryAsset(url, transformation, isVideo)

  return cloudinaryAsset.toURL()
}

export const getCloudinaryImages = () => document.querySelectorAll('img.cloudinary-image')

export const setImageSources = (imageArray) => {
  imageArray.forEach(imageElement => {
    setImageSource(imageElement)
  })
}
export const setImageSource = (imageElement) => {
  if (imageElement.dataset.transformation !== undefined) {
    setCloudinaryImage(imageElement, imageElement.dataset.src, imageElement.dataset.transformation)
  } else {
    setCloudinaryImage(imageElement, imageElement.dataset.src)
  }
}

export const setCloudinaryImage = (element, url, transformation) => {
  const newImage = makeCloudinaryAsset(url, transformation)
  if (isSkipTransform(url)) {
    return
  }

  const image = new HtmlImageLayer(element, newImage, defaultPlugins, false)
  if (typeof window === 'undefined') {
    return
  }

  window.cloudinaryImages = window.cloudinaryImages || []
  window.cloudinaryImages.push({
    id: element.id,
    imageLayer: image
  })
}

/**
 * Re-render all existing HTMLImageLayer components after a short delay to make sure the state
 * is updated in the entire application
 *
 * @param {string|null} parentSelector
 */
export const updateCloudinaryImages = (parentSelector) => {
  if (typeof window === 'undefined') {
    return
  }

  setTimeout(() => {
    (window.cloudinaryImages ?? []).forEach(image => {
      const newUrl = image.imageLayer.imgElement.dataset.src
      if ((parentSelector && !image.imageLayer.imgElement.closest(parentSelector)) || !newUrl) {
        return
      }

      const { transformation } = image.imageLayer.imgElement.dataset
      const newImage = makeCloudinaryAsset(newUrl, transformation)
      image.imageLayer.update(newImage, defaultPlugins, false)
    })
  }, 50)
}
