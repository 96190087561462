const initialState = () => ({
  sections: []
})

const getters = {
  sections: state => state.sections
}

const actions = {
  addSection ({ commit }, section) {
    commit('addSection', section)
  }
}

const mutations = {
  addSection (state, section) {
    state.sections.push(section)
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
