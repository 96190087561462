<template>
  <section
    v-if="usps"
    class="bg-light-grey flex justify-center items-center content-center pt-[70px] pb-[50px] md:pt-[150px] md:pb-[130px]"
    data-testid="usps">
    <div class="container">
      <Slogan
        htmlTag='h3'
        :text="usps.tagline"
        sloganClass='slogan text-black text-h3 md:text-h1 mb-3.5 md:pb-[40px] font-bold max-w-[970px] text-center m-auto !leading-[1.1]'
      />
      <div class="flex justify-around lg:justify-center flex-wrap flex-row gap-[30px]">
        <div v-for="(usp, index) in usps.items" :key="index">
          <USP
            lazyLoad
            :description="usp.title"
            :imageUrl="usp.image === null ? placeholder : usp.image"
            wrapperClass="usp-wrapper mt-[20px] !max-w-[146px] md:!max-w-[270px] h-![300px]"
            imageClass="!max-h-[110px] !mb-[35px] !w-[auto]"
            descriptionClass="text-medium text-black md:text-large break-normal leading-normal"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Slogan, USP } from '@ponbike/vue-components'
import { mapGetters } from 'vuex'

export default {
  name: 'USPS',
  components: {
    Slogan,
    USP
  },
    computed: {
    ...mapGetters('product', [
      'usps',
    ]),
    placeholder() {
      return 'https://cloudinary.pondigital.solutions/pon-digital-solutions/image/upload/v1666862859/dmp.pon.bike/placeholder-icon-big_g2s58q.png'
    }
  },
}
</script>
