<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div
    :id="id"
    class="container"
  >
    <div class="flex flex-wrap md:justify-between my-12 md:my-24 items-center">
      <div class="w-full lg:w-7/12">
        <div class="!block lg:!hidden">
          <h3
            class="mt-0 mb-5 text-h3 leading-normal font-bold pr-5"
          >
            {{ getContent('model_viewer_title') }}
            <button
              @click="toggle3DControlsModal"
              class="bg-white cursor-pointer hidden md:!block"
            >
              <span class="vue-icon-info text-medium-large" />
            </button>
          </h3>
          <p
            class="text-light-black mb-8"
            data-testid="rotatable-description">
            {{ description }}
          </p>
        </div>
        <div class="relative">
          <model-viewer
            v-if="isMounted && activeModelUrl"
            id="model-viewer-ar"
            :alt="getContent('model_viewer_title')"
            :src="activeModelUrl"
            ar
            ar-scale="fixed"
            shadow-intensity="1"
            camera-controls
            touch-action="pan-y"
            camera-orbit="0deg 75deg 4.5m"
            min-camera-orbit="auto auto 1m"
            camera-target="0.07273m 0.64m -0.00002364m"
            field-of-view="22.22deg"
            disable-tap
            class="block w-full min-h-[25vh] md:min-h-[50vh] h-full aspect-video"
          >
            <div class="!hidden" slot="progress-bar" />
            <button slot="ar-button" class="!hidden"/>
          </model-viewer>
          <div
            v-if="!modelViewerHasLoaded"
            class="absolute inset-0 flex items-center justify-center"
          >
            <Loader />
          </div>
          <Modal
            :transitionEnabled="true"
            :open="is3DControlsModalOpen"
            @close="toggle3DControlsModal"
            class="model-viewer-modal p-0 sm:p-5 z-3d-modal"
          >
            <template #header>
              <h3 class="text-large font-bold m-0">
                {{ getContent('model_viewer_modal_title') }}
              </h3>
            </template>
            <template #body>
              <ul class="space-y-6">
                <li class="flex items-center gap-2">
                  <span class="inline-block bg-mouse-left-click bg-no-repeat bg-center h-11 w-11" />
                  <strong>{{ getContent('left_mouse_button') }}</strong> - {{ getContent('left_mouse_button_control') }}
                </li>
                <li class="flex items-center gap-2">
                  <span class="inline-block bg-mouse-right-click bg-no-repeat bg-center h-11 w-11" />
                  <strong>{{ getContent('right_mouse_button') }}</strong> - {{ getContent('right_mouse_button_control') }}
                </li>
                <li class="flex items-center gap-2">
                  <span class="inline-block bg-mouse-scroll bg-no-repeat bg-center h-11 w-11" />
                  <strong>{{ getContent('scrolling') }}</strong> - {{ getContent('scrolling_control') }}
                </li>
              </ul>
            </template>
          </Modal>
        </div>
      </div>
      <div class="w-full md:sm-0 px-4 lg:w-4/12">
        <div class="!hidden lg:!block">
          <h3
            class="mt-0 mb-5 text-h3 leading-normal font-bold pr-5"
          >
            {{ getContent('model_viewer_title') }}
            <button
              @click="toggle3DControlsModal"
              class="bg-white cursor-pointer"
            >
              <span class="vue-icon-info text-medium-large" />
            </button>
          </h3>
          <p
            class="text-light-black mb-8"
            data-testid="rotatable-description">
            {{ description }}
          </p>
        </div>
        <Swatches class="mb-8" />
        <button
          class="bg-white text-blue font-bold px-5 py-2 mb-4 border-2 border-blue rounded-sm text-left flex items-center gap-2 transition duration-300 hover:bg-blue-hover"
          @click="handleAR()"
        >
          <span class="vue-icon-ar text-medium text-blue transition duration-300" />
          {{ arButtonLabel }}
          <span class="vue-icon-arrow-right text-sm font-bold text-blue transition duration-300" />
        </button>
        <p class="m-0 text-brown-grey text-tiny">
          {{ getContent('ar_compatibility') }}
        </p>
        <Modal
          :transitionEnabled="true"
          :open="isARModalOpen"
          @close="toggleARModal"
          class="model-viewer-modal p-0 sm:p-5 z-3d-modal"
        >
          <template #header>
            <h3 class="text-large font-bold m-0">
              {{ getContent('ar_modal_title') }}
            </h3>
          </template>
          <template #body>
            <p class="m-0 text=medium-light-black">
              {{ getContent('ar_modal_subtitle') }}
            </p>
            <img
              :src="QRCodeDataUrl"
              :alt="getContent('ar_modal_title')"
              class="w-[200px] mx-auto my-8"
            />
            <p class="m-0 text-center text-medium-light-black w-3/4 mx-auto">
              {{ getContent('ar_compatibility') }}
            </p>
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal, Loader } from '@ponbike/vue-components'
import { mapGetters } from 'vuex'
import QRCode from 'qrcode'
import Swatches from '../Swatches/Swatches.vue'
import { isSSR } from '../../../helpers/application.js'

export default {
  name: 'ARViewer',
  components: {
    Modal,
    Loader,
    Swatches
  },
  data() {
    return {
      id: 'ar-viewer',
      isMounted: false,
      modelViewerHasLoaded: false,
      arButtonLabel: ''
    }
  },
  mounted() {
    this.isMounted = true
    this.arButtonLabel = this.getContent('view_in_ar')
    this.loadComponent()
    this.$store.dispatch('sections/addSection', this.id)

    if (isSSR) {
      return
    }

    // Minimal timeout to make sure the model-viewer element exists
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search)
      const modelViewer = document.querySelector('#model-viewer-ar')

      document.querySelector('#model-viewer-ar')?.addEventListener('load', () => {
        this.modelViewerHasLoaded = true
        this.arButtonLabel = modelViewer.canActivateAR ? this.getContent('open_camera') : this.getContent('view_in_ar')

        if (searchParams.get('ar') === '1' && modelViewer.canActivateAR) {
          modelViewer.activateAR()
        }
      })

    }, 100)
   },
  computed: {
    ...mapGetters('product', [
      'name',
      'familyName',
      'getContent',
      'getARModelByTags'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    ...mapGetters('modal', [
      'is3DControlsModalOpen',
      'isARModalOpen'
    ]),
    activeModelUrl() {
      const model = this.getARModelByTags(this.activeColor, this.activeFrame)
      return model?.file_url
    },
     description() {
      return this.getContent('model_viewer_description').replace('%s', `${this.familyName} ${this.name}`)
     },
     QRCodeDataUrl() {
      if (isSSR) {
        return
      }

      let imgDataUrl
      const opts = {
        errorCorrectionLevel: 'H',
        quality: 1,
        margin: 0
      }
      const destinationURL = new URL(window.location.href)
      destinationURL.searchParams.set('ar', 1)
      destinationURL.searchParams.set('color', this.activeColor)
      destinationURL.searchParams.set('frame', this.activeFrame.replace('frame-', ''))

      QRCode.toDataURL(destinationURL.href, opts, function (err, url) {
        imgDataUrl = url
      })

      return imgDataUrl
     }
  },
  methods: {
    loadComponent() {
      require('@google/model-viewer/dist/model-viewer-umd')
     },
     handleAR() {
      const modelViewer = document.querySelector('#model-viewer-ar')

      if (modelViewer.canActivateAR) {
        modelViewer.activateAR()
        return
      }

      this.toggleARModal()
    },
    toggleARModal() {
      this.$store.dispatch('modal/toggleARModal')
    },
     toggle3DControlsModal() {
      this.$store.dispatch('modal/toggle3DControlsModal')
    }
  }
}
</script>
