<template>
  <div v-show="featureVideos.length >= 1">
    <section
        id="video-block"
        v-for="(video, index) in featureVideos"
        :key="video.id"
        class="bg-video-block-grey"
    >
      <div class="relative bg-medium-dark-grey">
        <video
          ref="video"
          :key="videoKey"
          @ended="isPlaying = false"
          playsInline
          muted
          preload="none"
          :poster="getVideoPoster(video.image_url)"
          class="object-cover lg:h-[80vh] lg:w-[50%] w-full lg:top-0 lg:absolute"
          :class="!index % 2 === 0 ? 'lg:left-0' : 'lg:right-0'"
        >
          <source
            :src="getVideoUrl(video.image_url)"
            type="video/mp4"
          >
        </video>
        <div class="absolute lg:top-[10%] top-6 right-7"
             :class="!index % 2 === 0 ? 'lg:left-[5%] lg:right-auto' : 'lg:right-[40%] lg:left-auto'">
          <div class="pointer-events-none w-[58px] h-[58px] bg-blue opacity-25 rounded-full absolute animate-ping md:w-[84px] md:h-[84px]" />
          <button
            data-testid="play-button"
            class="relative w-[58px] h-[58px] bg-blue rounded-full flex items-center justify-center md:w-[84px] md:h-[84px]"
            @click="togglePlay(index)">
            <span :class="isPlaying && playingIndex === index ? 'vue-icon-pause' : 'vue-icon-play'" class="text-white" />
          </button>
        </div>
        <div class="container">
          <div class="flex flex-wrap items-center lg:min-h-[80vh]"
               :class="{ 'flex-row-reverse' : !index % 2 === 0 }">
            <div
              v-for="content in filteredFeatures(video)"
              :key="content.id"
              class="w-full flex flex-col p-4 lg:p-[35px] lg:w-4/12 md:flex">
              <div>
                <h3 class="text-black text-[32px] lg:text-h2 leading-normal font-bold">{{ content.title }}</h3>
                <p class="mt-3 text-base font-normal">{{ content.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { transformUrl } from '../../../helpers/cloudinary.js'

export default {
  name: 'VideoBlock',
  data() {
    return {
      isPlaying: false,
      playingIndex: null,
      videoKey: 0
    }
  },
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'getFeatures'
    ]),
    ...mapGetters('swatches', [
      'activeColor',
      'activeFrame'
    ]),
    featureVideos() {
      return this.filterImageByTags('feature-video', this.activeColor, this.activeFrame)
    }
  },
  watch: {
    activeColor() {
      this.videoKey += 1
      this.isPlaying = false
    },
    activeFrame() {
      this.videoKey += 1
      this.isPlaying = false
    }
  },
  methods: {
    togglePlay(number) {
      const video = this.$refs.video[number]

      if (this.isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      this.isPlaying = !this.isPlaying
      this.playingIndex = number
    },
    getVideoUrl(input) {
      return transformUrl(input, [
        'q_auto',
        'f_auto',
        'dpr_auto',
        'c_crop',
        'e_boomerang'
      ], true)
    },
    getVideoPoster(url) {
      return transformUrl(url, [
        'q_auto:best',
        'c_crop',
        'so_0',
        'f_jpg'
      ], true)
    },
    filteredFeatures(video) {
      return this.getFeatures.filter(content =>
        video.tags.includes('feature-' + content.id)
      )
    }
  }
}
</script>
