<template>
  <div class="container">
    <div class="flex flex-wrap md:justify-between items-center md:mb-12 mt-[50px]">
      <div class="w-full flex-col lg:w-7/12 hidden md:!block">
        <Rotatable />
      </div>
      <div class="w-full md:mt-8 md:sm-0 flex flex-col px-4 lg:w-4/12">
        <h3 class="mb-5 text-h3 leading-normal font-normal"
            v-html="rotatableQuote">
        </h3>
        <p class="text-light-black" data-testid="rotatable-description" v-html="description"></p>
        <div class="w-full flex flex-col lg:w-7/12 md:hidden mb-12">
          <Rotatable />
        </div>
        <div class="mt-8 flex justify-center items-center md:block">
          <Swatches class="mr-2.5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rotatable from '../Rotatable/Rotatable.vue'
import Swatches from '../Swatches/Swatches.vue'
import { mapGetters } from 'vuex'
import { addActiveSwatchesTags } from '../../../helpers/image.js'

export default {
  name: 'ProductRotatable',
  components: {
    Rotatable,
    Swatches
  },
  computed: {
    ...mapGetters('product', [
      'familyName',
      'name',
      'filterImageByTags',
      'description',
      'getContent'
    ]),
    isThreeSixty() {
      const tags = addActiveSwatchesTags(['360'])

      return this.filterImageByTags(...tags).length > 1
    },
    rotatableQuote() {
      return this.getContent('rotatable_quote')
        .replace('%d', `<strong>${this.familyName} ${this.name}</strong>`)
        .replace('%s', this.isThreeSixty ? '<strong>360&deg;</strong>' : `<strong>${this.getContent('complete')}</strong>`)
    }
  }
}
</script>
