<template>
  <div v-if="awards.length >= 1"
       id="awards"
       :class="[isEclipseBike ? 'md:mt-0' : 'md:mt-[100px]']"
       class="bg-[#f2f2f2] py-8 mt-[50px]"
  >
    <div class="container">
      <div class="w-full flex flex-wrap items-center justify-between px-4 md:px-0 my-12">
        <div class="w-full md:w-6/12 lg:w-4/12">
          <h3 class="text-black text-center md:text-left text-[28px] md:text-h3 leading-normal"
              v-html="awardLabel">
          </h3>
        </div>
        <div class="w-full mt-10 md:mt-0 md:w-5/12 flex items-center justify-center gap-4 md:gap-7">
          <img
            v-for="award in awards"
            loading="lazy"
            :key="award.title"
            :data-src="award.image_url"
            data-transformation="e_trim"
            :alt="award.title"
            class="inline-block w-[125px] h-[125px] object-contain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'productAwards',
  computed: {
    ...mapGetters('product', [
      'filterImageByTags',
      'name',
      'familyName',
      'getContent',
      'isEclipseBike'
    ]),
    awards() {
      return this.filterImageByTags('award')
    },
    awardLabel() {
      return this.getContent('awards_label')
        .replace('%s', `<strong>${this.familyName} ${this.name}</strong>`)
    }
  }
}
</script>
