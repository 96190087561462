const initialState = () => ({
  config: {
    app: ''
  }
})

const getters = {
  // eslint-disable-next-line
  config: (state) => state.config,
  // eslint-disable-next-line
  app: (state) => state.config.app
}

const actions = {}

const mutations = {
  setConfig (state, config) {
    // eslint-disable-next-line
    state.config = config
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
