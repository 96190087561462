import store from '../store'

export const setupStoreData = async (requestBody) => {
  store.commit('app/setConfig', requestBody.config)

  if (requestBody.config.productData) {
    store.commit('product/setData', requestBody.config.productData)
  }

  if (store.getters['product/availableFilteredColors']) {
    const color = store.getters['product/availableFilteredColors'][0]
    store.commit('swatches/setActiveColor', color.tag_identifier)
  }

  if (store.getters['product/availableFrames']) {
    const frame = store.getters['product/availableFrames'][0]
    store.commit('swatches/setActiveFrame', frame)
  }
}

export const isSSR = typeof window === 'undefined'
