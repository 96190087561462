<template>
  <section v-if="video?.url?.length > 1" class="py-[50px] md:pt-[150px] md:pt-[70px]">
    <div class="container">
      <div class="flex flex-col px-4">
        <div class="order-1 md:order-2 flex flex-col md:flex-row md:justify-between">
          <h4 class="text-medium-large font-bold mb-5 md:text-h4 md:basis-1/5" data-testid="video-title">
            {{ video.title }}
          </h4>
          <p class="text-light-black mb-5 md:basis-3/5" data-testid="video-description">
            {{ video.description }}
          </p>
        </div>
        <div class="relative order-3 md:order-1 md:mb-16">
          <iframe ref="iframe"
                  :title="video.title || 'Video'"
                  :data-src="video.url"
                  class="aspect-video w-full"
                  data-testid="iframe-video"
                  allow="autoplay"
          >
          </iframe>
          <img v-if="!isVideoPlaying"
               loading="lazy"
               :src="placeholderImage"
               alt="Video placeholder" />
          <div v-if="!isVideoPlaying" class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <div class="pointer-events-none w-[50px] h-[50px] bg-blue opacity-25 rounded-full absolute animate-ping md:w-[75px] md:h-[75px]"></div>
            <button
              title="Play"
              class="relative w-[58px] h-[58px] bg-blue rounded-full flex items-center justify-center md:w-[84px] md:h-[84px] after:content-[''] after:border-t-[6px] after:border-b-[6px] after:border-l-[12px] after:block md:after:border-t-8 md:after:border-b-8 md:after:border-l-[16px] after:border-l-white after:border-t-transparent after:border-b-transparent"
              @click.prevent="handleClick"
              data-testid="iframe-button">
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductVideo',
  data() {
    return {
      isVideoPlaying: false
    }
  },
  computed: {
    ...mapGetters('product', [
      'video'
    ]),
    videoId() {
      if (this.video.url.includes('/embed/')) {
        return this.video.url.split('/embed/')[1].split('?')[0]
      }

      return ''
    },
    placeholderImage() {
      return `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`
    }
  },
  methods: {
    handleClick() {
      const iframe = this.$refs.iframe
      const src = iframe.dataset.src
      const query = src.indexOf('?') > 0
        ? '&autoplay=1'
        : '?autoplay=1'

      iframe.src = src + query

      this.isVideoPlaying = true
    }
  }
}
</script>

<style scoped>
img {
  position: absolute;
  top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
